import React from 'react'
import { Helmet } from "react-helmet"
import tw, {styled} from 'twin.macro'
import { db, collectionOptions, getDocsDate } from '../configs/firebase'
import {useI18n} from "use-mini18n"
import { useCollection } from "react-firebase-hooks/firestore";
import { doc, collection, setDoc } from "firebase/firestore"
import { Artwork } from "../components/Artwork"
import { Statement } from "../components/Statement"
import { artwork } from "../configs/2021_artwork"
import {
  ArtworkImage1, ArtworkImage2, ArtworkImage3, ArtworkImage4, ArtworkImage5, ArtworkImage6, ArtworkImage7, ArtworkImage8, ArtworkImage9, ArtworkImage10,
  ArtworkImage11, ArtworkImage12, ArtworkImage13, ArtworkImage14, ArtworkImage15, ArtworkImage16, ArtworkImage17, ArtworkImage18, ArtworkImage19, ArtworkImage20,
  ArtworkImage21, ArtworkImage22, ArtworkImage23, ArtworkImage24, ArtworkImage25, ArtworkImage26, ArtworkImage27, ArtworkImage28, ArtworkImage29, ArtworkImage30,
} from '../assets/artworkImage'
import LogoArs from '../assets/images/logo_ars.png'
import ExhibitPreview from '../assets/images/Exhibition_view_preview.png'

const Container = tw.div`bg-gray-300 tracking-wider`

const Hero = () => {
  const { t, lang } = useI18n()
  const HeadContainer = tw.div`absolute bottom-6 right-5 md:bottom-10 md:right-8 bg-gray-300 flex items-end flex-col`
  const Container = tw.div`relative max-w-screen-xl mx-auto min-h-[60vh] md:min-h-screen text-center flex justify-end md:justify-center items-center flex-col px-5 pt-10 pb-10 md:px-0 md:py-0`
  const MainVisual = styled.img(() => [`width: 1000px;`])
  const ArsContainer = tw.div`absolute top-6 left-5 md:top-10 md:left-8`
  const LogoArsImage = tw.img`w-64 md:w-80`
  return (
    <Container>
      <ArsContainer>
        <a href="https://calls.ars.electronica.art/2022/prix/winners/10848/"><LogoArsImage src={LogoArs} /></a>
      </ArsContainer>
      <MainVisual src={ExhibitPreview} />
    </Container>
  )
}

const Annotation = () => {
  const { t } = useI18n()
  const Container = tw.div`w-full max-w-screen-xl mx-auto px-5 md:px-0 md:px-20 mb-28 md:mb-20`
  // const Inner = tw.div`border-8 bg-white border-yellow-400 p-5 leading-8 text-red-500 font-bold text-lg`
  const TitleContainer = tw.div``
  const Title = tw.h1`w-full text-base md:text-xl leading-relaxed font-bold pb-4`
  const Sign = tw.p`text-xs md:text-lg pr-0 md:pr-3 pt-2 text-right`
  const Description = tw.div`w-full max-w-screen-lg mt-10 md:mt-20 py-6 px-6 md:py-6 md:px-10 bg-gray-200 text-justify mx-auto leading-relaxed`
  return (
    <Container>
      <Description>
        <TitleContainer>
          <Title>{t['hero_title']}</Title>
        </TitleContainer>
        <p>{t['hero_desc']}</p>
        <Sign>{t['hero_date']}</Sign>
      </Description>
    </Container>
    //   <Inner>
    //     <p>{t['anno_desc1']}</p><br/>
    //     <p>{t['anno_desc2']}</p><br/>
    //     <p>{t['anno_desc3']}</p>
    //   </Inner>
  )
}

const Project = () => {
  const { t, lang } = useI18n()
  const Container = tw.div`w-full max-w-screen-lg mx-auto px-5 md:px-20`
  const Title = tw.h2`text-2xl font-bold`
  const WrapProtocolList = tw.ul`pl-8 list-decimal text-lg pt-10 pb-20`
  const List = tw.li`mb-5`
  return (
    <Container>
      <Title>{t['pj_title']}</Title>
      <WrapProtocolList>
        <List>{t['pj_desc1']}</List>
        <List>{t['pj_desc2']}</List>
        <List>{t['pj_desc3']}</List>
        <List>{t['pj_desc4']}</List>
        <List>{t['pj_desc5']}</List>
        { lang === 'ja' && <List>{t['pj_desc6']}</List> }
      </WrapProtocolList>
    </Container>
  )
}

const ArtworkList = ({setFunc, artwork}) => {
  const { t, lang } = useI18n()
  const Container = tw.div`w-full max-w-screen-lg mx-auto px-5 md:px-20 pb-10 md:pb-20`
  const WrapArtwork = tw.div`w-full md:w-1/2 my-10 md:my-20 mx-auto md:px-5`
  const Title = tw.h2`text-2xl font-bold`
  return (
    <Container>
      <Title>{t['works_title']}</Title>
      <WrapArtwork><Artwork src={ArtworkImage1} num={"001"} title={"tmp"} create_at={t['work1_date']} desc={t['work1_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state1_1']}  />
      <WrapArtwork><Artwork src={ArtworkImage2} num={"002"} title={"background"} create_at={t['work2_date']} desc={t['work2_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state1_2']}  />
      <WrapArtwork><Artwork src={ArtworkImage3} num={"003"} title={"DONDONs3"} create_at={t['work3_date']} desc={t['work3_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state2_1']}  />
      <WrapArtwork><Artwork src={ArtworkImage4} num={"004"} title={"body2"} create_at={t['work4_date']} desc={t['work4_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state2_2']}  />
      <WrapArtwork><Artwork src={ArtworkImage5} num={"005"} title={"swapper"} create_at={t['work5_date']} desc={t['work5_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state3_1']}  />
      <WrapArtwork><Artwork src={ArtworkImage6} num={"006"} title={"xx"} create_at={t['work6_date']} desc={t['work6_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state3_2']}  />
      <WrapArtwork><Artwork src={ArtworkImage7} num={"007"} title={"DONDONs"} create_at={t['work7_date']} desc={t['work7_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state4_1']}  />
      <WrapArtwork><Artwork src={ArtworkImage8} num={"008"} title={"body4"} create_at={t['work8_date']} desc={t['work8_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state5_1']}  />
      <WrapArtwork><Artwork src={ArtworkImage9} num={"009"} title={"mask2"} create_at={t['work9_date']} desc={t['work9_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage10} num={"010"} title={"tileman"} create_at={t['work10_date']} desc={t['work10_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state6_1']}  />
      <WrapArtwork><Artwork src={ArtworkImage11} num={"011"} title={"telephone"} create_at={t['work11_date']} desc={t['work11_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state6_2']}  />
      <WrapArtwork><Artwork src={ArtworkImage12} num={"012"} title={"kappa-V2"} create_at={t['work12_date']} desc={t['work12_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage13} num={"013"} title={"kappa2"} create_at={t['work13_date']} desc={t['work13_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage14} num={"014"} title={"kappas"} create_at={t['work14_date']} desc={t['work14_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage15} num={"015"} title={"kappa"} create_at={t['work15_date']} desc={t['work15_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state7_1']}  />
      <WrapArtwork><Artwork src={ArtworkImage16} num={"016"} title={"Ekubo30"} create_at={t['work16_date']} desc={t['work16_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage17} num={"017"} title={"Ekubo00"} create_at={t['work17_date']} desc={t['work17_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage18} num={"018"} title={"Ekubo20"} create_at={t['work18_date']} desc={t['work18_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state7_2']}  />
      <WrapArtwork><Artwork src={ArtworkImage19} num={"019"} title={"xx"} create_at={t['work19_date']} desc={t['work19_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage20} num={"020"} title={"pokan"} create_at={t['work20_date']} desc={t['work20_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage21} num={"021"} title={"kabu"} create_at={t['work21_date']} desc={t['work21_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state7_3']}  />
      <WrapArtwork><Artwork src={ArtworkImage22} num={"022"} title={"lionbody4"} create_at={t['work22_date']} desc={t['work22_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state8_1']}  />
      <WrapArtwork><Artwork src={ArtworkImage23} num={"023"} title={"face"} create_at={t['work23_date']} desc={t['work23_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage24} num={"024"} title={"suuji-mask"} create_at={t['work24_date']} desc={t['work24_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage25} num={"025"} title={"lion5"} create_at={t['work25_date']} desc={t['work25_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage26} num={"026"} title={"axis"} create_at={t['work26_date']} desc={t['work26_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage27} num={"027"} title={"Gas"} create_at={t['work27_date']} desc={t['work27_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage28} num={"028"} title={"DONDONs2"} create_at={t['work28_date']} desc={t['work28_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage29} num={"029"} title={lang === 'ja' ? "リフレクター" : "Reflector"} create_at={t['work29_date']} desc={t['work29_desc']} artwork={artwork} /></WrapArtwork>
      <WrapArtwork><Artwork src={ArtworkImage30} num={"030"} title={"tmp"} create_at={t['work30_date']} desc={t['work30_desc']} artwork={artwork} /></WrapArtwork>
      <Statement desc={t['state8_2']}  />
    </Container>
  )
}

const COLLECTION_NAME = 'artwork'

const Home = () => {
  // const [artworkCollection] = useCollection(collection(db, COLLECTION_NAME), collectionOptions)
  // const artwork = getDocsDate(artworkCollection)
  const setArtworkAmount = async (id, amount) => {
    await setDoc(doc(db, COLLECTION_NAME, id), {
      amount: artwork?.filter(v => v.id === id)[0].amount + amount
    }, { merge: true })
  }
  const initSetArtwork = () => {
    [
      '001','002','003','004','005','006','007','008','009','010',
      '011','012','013','014','015','016','017','018','019','020',
      '021','022','023','024','025','026','027','028','029','030'
    ].map(async (v) => {
      await setDoc(doc(db, COLLECTION_NAME, v), {
        id: v,
        amount: 1,
        price: 100000,
      })
    })
  }

  return (
    <Container>
      <Helmet>
        <meta property="og:image" content={`https://mf.3331.jp/ogp.jpg`} />
      </Helmet>
      <Hero />
      <Annotation />
      <Project />
      <ArtworkList setFunc={setArtworkAmount} artwork={artwork} />
    </Container>
  )
}

export default Home
