import React, {useCallback} from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from "use-mini18n"
import {useRecoilState, useRecoilCallback} from "recoil"
import {selectPurchaseArtworkState} from "../store/app"
import {pullAllBy, isEqual} from "lodash-es";

const Container = styled.div(({selected}) => [
  tw`w-full mt-2 py-4 md:p-2 cursor-pointer hover:bg-yellow-200`,
  selected && tw`bg-yellow-300 hover:bg-yellow-300`
])
const Title = tw.h2`text-sm font-bold flex gap-x-4 justify-center mb-2 flex-col items-stretch`
const WrapInfo = tw.div`mt-3 mb-0 md:mb-2 text-center`
const Text = tw.p``
const WrapPrices = tw.div`mt-0`
const CurrentPrice = tw.p`text-xs md:text-sm text-center`
const Image = tw.img`h-40 md:h-60 object-contain mx-auto`

export const ArtworkPurchase = (props) => {
  const [selectedPurchaseArtwork, setSelectedPurchaseArtwork] = useRecoilState(selectPurchaseArtworkState)
  const { src, num, title, artwork } = props
  const { t } = useI18n()
  const _artwork = artwork?.filter(v => v.id === num)[0]
  const currentPrice = _artwork && parseInt(_artwork.price / _artwork.amount).toLocaleString()
  const handleTogglePurchase = useCallback(() => {
    const artworkNums = selectedPurchaseArtwork.map(v => v.artwork)
    if (artworkNums.includes(num)) {
      const result = Object.assign([], selectedPurchaseArtwork)
      pullAllBy(result, [{artwork: num}], 'artwork')
      setSelectedPurchaseArtwork(result)
    } else {
      const result = Object.assign([], selectedPurchaseArtwork)
      result.push({
        artwork: num,
        amount: 1,
      })
      setSelectedPurchaseArtwork(result)
    }
  }, [num, selectedPurchaseArtwork, setSelectedPurchaseArtwork])
  return (
    <Container
      onClick={handleTogglePurchase}
      selected={selectedPurchaseArtwork.map(v=>v.artwork).includes(num)}
    >
      <Image src={src} alt={title}/>
      <WrapInfo>
        <Title>
          <Text>{t['work_num']}: {num}</Text>
          <Text>{t['work_title']}: {title}</Text>
        </Title>
      </WrapInfo>
      <WrapPrices>
        <CurrentPrice>{`${t['work_price']}: ${currentPrice}${t['work_price_unit']}`}</CurrentPrice>
        <CurrentPrice>{`(${_artwork?.price.toLocaleString()}${t['work_price_unit']} / ${_artwork?.amount})`}</CurrentPrice>
      </WrapPrices>
    </Container>
  )
}
