import React, {useEffect} from 'react'
import { Helmet } from "react-helmet"
import { mdiTwitter, mdiFacebook, mdiMail } from "@mdi/js"
import Icon from '@mdi/react'
import tw, {styled} from 'twin.macro'
import {db, collectionOptions, getDocsDate} from '../configs/firebase'
import {fetchReservationComplete} from "../api";
import { useParams } from "react-router-dom"
import {useI18n} from "use-mini18n"
import {useCollection} from "react-firebase-hooks/firestore";
import {useRecoilState} from "recoil"
import {doc, collection, setDoc, addDoc} from "firebase/firestore"
import { Artwork as CardArtwork } from "../components/Artwork"
import {
  ArtworkImage1,
  ArtworkImage10,
  ArtworkImage11,
  ArtworkImage12,
  ArtworkImage13,
  ArtworkImage14,
  ArtworkImage15,
  ArtworkImage16,
  ArtworkImage17,
  ArtworkImage18,
  ArtworkImage19,
  ArtworkImage2,
  ArtworkImage20,
  ArtworkImage21,
  ArtworkImage22,
  ArtworkImage23,
  ArtworkImage24,
  ArtworkImage25,
  ArtworkImage26,
  ArtworkImage27,
  ArtworkImage28,
  ArtworkImage29,
  ArtworkImage3,
  ArtworkImage30,
  ArtworkImage4,
  ArtworkImage5,
  ArtworkImage6,
  ArtworkImage7,
  ArtworkImage8,
  ArtworkImage9
} from "../assets/artworkImage"

const COLLECTION_NAME = 'artwork'
const Container = tw.div`bg-gray-300 tracking-wider py-20`
const ArtworkContainer = tw.div`w-full max-w-screen-lg mx-auto px-5 md:px-20 pb-10 md:pb-20`
const WrapArtwork = tw.div`w-full md:w-1/2 my-10 md:my-20 mx-auto md:px-5`
const ShareContainer = tw.div`w-full flex justify-around mt-6 px-6`

const titles = {
  '1': { number: '001', title: 'tmp', image: ArtworkImage1 },
  '2': { number: '002', title: 'background', image: ArtworkImage2  },
  '3': { number: '003', title: 'DONDONs3', image: ArtworkImage3  },
  '4': { number: '004', title: 'body2', image: ArtworkImage4  },
  '5': { number: '005', title: 'swapper', image: ArtworkImage5  },
  '6': { number: '006', title: 'xx', image: ArtworkImage6  },
  '7': { number: '007', title: 'DONDONs', image: ArtworkImage7  },
  '8': { number: '008', title: 'body4', image: ArtworkImage8  },
  '9': { number: '009', title: 'mask2', image: ArtworkImage9  },
  '10': { number: '010', title: 'tileman', image: ArtworkImage10  },
  '11': { number: '011', title: 'telephone', image: ArtworkImage11  },
  '12': { number: '012', title: 'kappa-V2', image: ArtworkImage12  },
  '13': { number: '013', title: 'kappa2', image: ArtworkImage13  },
  '14': { number: '014', title: 'kappas', image: ArtworkImage14  },
  '15': { number: '015', title: 'kappa', image: ArtworkImage15  },
  '16': { number: '016', title: 'Ekubo30', image: ArtworkImage16  },
  '17': { number: '017', title: 'Ekubo00', image: ArtworkImage17  },
  '18': { number: '018', title: 'Ekubo20', image: ArtworkImage18  },
  '19': { number: '019', title: 'xx', image: ArtworkImage19  },
  '20': { number: '020', title: 'pokan', image: ArtworkImage20  },
  '21': { number: '021', title: 'kabu', image: ArtworkImage21  },
  '22': { number: '022', title: 'lionbody4', image: ArtworkImage22  },
  '23': { number: '023', title: 'face', image: ArtworkImage23  },
  '24': { number: '024', title: 'suuji-mask', image: ArtworkImage24  },
  '25': { number: '025', title: 'lion5', image: ArtworkImage25  },
  '26': { number: '026', title: 'axis', image: ArtworkImage26  },
  '27': { number: '027', title: 'Gas', image: ArtworkImage27  },
  '28': { number: '028', title: 'DONDONs2', image: ArtworkImage28  },
  '29': { number: '029', title: 'リフレクター', image: ArtworkImage29  },
  '30': { number: '030', title: 'tmp', image: ArtworkImage30  },
}

const Artwork = () => {
  const { id } = useParams();
  const { t } = useI18n()
  const [artworkCollection] = useCollection(collection(db, COLLECTION_NAME), collectionOptions)
  const artwork = getDocsDate(artworkCollection)
  useEffect(() => {
    window.location.href = `/${titles[id].number}.html`
  }, [])
  return (
    <Container>
      <Helmet>
        <title>{`Number: ${titles[id].number} Title: ${titles[id].title}`} │ Masaki Fujihata</title>
        <meta property="og:image" content={`https://mf.3331.jp/${titles[id].number}.png`} />
      </Helmet>
      <ArtworkContainer>
        <WrapArtwork>
          <CardArtwork
            src={titles[id].image}
            num={titles[id].number}
            title={titles[id].title}
            create_at={t[`work${id}_date`]}
            desc={t[`work${id}_desc`]}
            artwork={artwork}
            no_detail
          />
          <ShareContainer>
            <a href={`https://twitter.com/share?url=https://mf.3331.jp/artwork/${id}&text=Number:%20${titles[id].number}%20Title:%20${titles[id].title}%20│%20Masaki%20Fujihata&hashtags=NFT,nftart,Brave_New_Commons,masakifujihata,藤幡正樹`}
               rel="nofollow noopener noreferrer" target="_blank"
            >
              <Icon path={mdiTwitter} size={1.5} color={"#00acee"} />
            </a>
            <a href={`https://www.facebook.com/share.php?u=https://mf.3331.jp/artwork/${id}`}
               rel="nofollow noopener noreferrer" target="_blank">
              <Icon path={mdiFacebook} size={1.5} color={'#39569c'} />
            </a>
          </ShareContainer>
        </WrapArtwork>
      </ArtworkContainer>
    </Container>
  )
}

export default Artwork