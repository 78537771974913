import fetch from 'isomorphic-unfetch'

const API_HOST = 'https://us-central1-bnc-web.cloudfunctions.net/api/'
// const API_HOST = 'http://localhost:5001/bnc-web/us-central1/api/'

const RESERVATION_COMPLETE = 'reservation-complete'

const apiFactory = (endpoint) => (`${API_HOST}${endpoint}`)

const queryFactory = (data) => {
  return {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({data})
  }
}

export const fetchReservationComplete = async (data) => {
  return await fetch(apiFactory(RESERVATION_COMPLETE), queryFactory(data)).then(res => res.json()).then(data => data)
}
