import React,{useCallback} from 'react'
import tw, {styled} from 'twin.macro'
import {useI18n} from "use-mini18n"
import {useHistory} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {selectPurchaseArtworkState} from "../store/app"
import {ButtonRounded} from "./ButtonRounded"
import {pullAllBy} from "lodash-es";

const Container = tw.div`w-full`
const LinkPurchase = tw.button`w-full font-bold h-12 md:h-16 flex items-center justify-center bg-yellow-300 hover:bg-yellow-200 rounded-full`
const LinkDetail = tw.a`w-full font-bold h-12 md:h-16 flex items-center justify-center bg-gray-400 hover:bg-yellow-200 rounded-full cursor-pointer`
const Title = styled.h2(({list}) => [
  tw`font-bold flex gap-x-4 justify-center mb-2`,
  list && tw`flex-col items-stretch`
])
const WrapInfo = tw.div`mt-6 mb-5 text-center`
const Text = tw.p``
const WrapButtons = tw.div`flex flex-col gap-y-4 mt-6`
const Image = styled.img(({list}) => [
  tw`mx-auto`,
  list && tw`h-40 md:h-60 object-contain mx-auto`
])

export const Artwork = (props) => {
  const [selectedPurchaseArtwork, setSelectedPurchaseArtwork] = useRecoilState(selectPurchaseArtworkState)
  const history = useHistory()
  const { src, num, title, create_at, desc, artwork, list, no_detail } = props
  const { t } = useI18n()
  const _artwork = artwork?.filter(v => v.id === num)[0]
  const currentPrice = _artwork && parseInt(_artwork.price / _artwork.amount).toLocaleString()
  const handleTogglePurchase = useCallback(() => {
    const artworkNums = selectedPurchaseArtwork.map(v => v.artwork)
    if (artworkNums.includes(num)) {
      const result = Object.assign([], selectedPurchaseArtwork)
      pullAllBy(result, [{artwork: num}], 'artwork')
      setSelectedPurchaseArtwork(result)
    } else {
      const result = Object.assign([], selectedPurchaseArtwork)
      result.push({
        artwork: num,
        amount: 1,
      })
      setSelectedPurchaseArtwork(result)
    }
  }, [num, selectedPurchaseArtwork, setSelectedPurchaseArtwork])
  return (
    <Container>
      <Image src={src} alt={title} list={list}/>
      <WrapInfo>
        <Title list={list}>
          <Text>{t['work_num']}: {num}</Text>
          <Text>{t['work_title']}: {title}</Text>
        </Title>
        { create_at && <Text>{t['work_date']}: {create_at}</Text>}
      </WrapInfo>
      { desc && <Text>{desc}</Text> }
      <WrapButtons>
        <ButtonRounded>
          <span>{`${t['work_price']}: ${currentPrice}${t['work_price_unit']} `}</span>
          <span>{`= (${_artwork?.price.toLocaleString()}${t['work_price_unit']} / ${_artwork?.amount})`}</span>
        </ButtonRounded>
        {/*<LinkPurchase onClick={() => {*/}
        {/*  handleTogglePurchase()*/}
        {/*  history.push('/purchase')*/}
        {/*}}>{t['button_purchase']}</LinkPurchase>*/}
        {
          !no_detail && (
            <LinkDetail href={`/artwork/${parseInt(num)}`} rel="nofollow noopener noreferrer" target="_blank">
              <span>{t['to_artwork_page']}</span>
            </LinkDetail>
          )
        }
      </WrapButtons>
    </Container>
  )
}
