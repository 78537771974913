import React from 'react'
import tw from 'twin.macro'

const Container = tw.div`w-full whitespace-pre-line bg-gray-400 p-5 md:p-10 leading-8`

export const Statement = ({desc}) => {
  return (
    <Container>{desc}</Container>
  )
}
