import React from 'react'
import tw from 'twin.macro'
import {useI18n} from "use-mini18n"

const Container = tw.div`pt-7 pb-4 px-5 md:px-10`
const Link = tw.a`text-blue-400 hover:text-blue-200`
const CopyAndPP = tw.div`pt-10 flex flex-col md:flex-row justify-between`

export const Footer = () => {
  const {t, lang} = useI18n()
  return (
    <Container>
      <p>{t['contact']}: <Link href="mailto:nft5@3331.jp">nft5@3331.jp</Link></p>
      { lang === 'ja' ? (
        <>
          <p>3331 Arts Chiyoda（運営：<Link href="https://commanda.info/" target="_blank" rel="noreferrer">合同会社コマンドA</Link>）</p>
          <br/>
          <p>{t['web_support']}: <Link href="https://akihirokato.com/" target="_blank" rel="noreferrer">加藤明洋</Link>（<Link href="https://drawcircle.jp/" target="_blank" rel="noreferrer">drawCircle合同会社</Link>）</p>
          <p>{t['coordinator_support']}: 津嘉山裕美（nutriment）</p>
          <p>{t['assistant_support']}: あらすけ</p>
          <p>
            <span>{t['support']}: </span>
            <span>小鍋藍子（<Link href="https://startbahn.jp/" target="_blank" rel="noreferrer">スタートバーン株式会社</Link>）, </span>
            <span>田島大資（<Link href="https://tissue.or.jp/" target="_blank" rel="noreferrer">TISSUE org.</Link>）</span>
          </p>
        </>
      ) : (
        <>
          <p>3331 Arts Chiyoda（Management：<Link href="https://commanda.info/" target="_blank" rel="noreferrer">commandA,LLC.</Link>）</p>
          <br/>
          <p>{t['web_support']}: <Link href="https://akihirokato.com/" target="_blank" rel="noreferrer">Akihiro Kato</Link>（<Link href="https://drawcircle.jp/" target="_blank" rel="noreferrer">drawCircle, Inc.</Link>）</p>
          <p>{t['coordinator_support']}: Himi Tsukayama（nutriment）</p>
          <p>{t['assistant_support']}: Arasuke</p>
          <p>
            <span>{t['support']}: </span>
            <span>Aiko Konabe（<Link href="https://startbahn.jp/en/" target="_blank" rel="noreferrer">
Startbahn, Inc.</Link>）, </span>
            <span>Daishi Tajima（<Link href="https://tissue.or.jp/" target="_blank" rel="noreferrer">TISSUE org.</Link>）</span>
          </p>
        </>
      )}
      <CopyAndPP>
        <p><Link href="https://www.commanda.info/pp.html" target="_blank" rel="noreferrer">{t['privacy_policy']}</Link></p>
        <p>©2021 commandA All Rights Reserved.</p>
      </CopyAndPP>
    </Container>
  )
}
