import React from 'react'
import tw, {styled} from 'twin.macro'
import { useI18n } from "use-mini18n"

const Container = styled.header(() => [
  tw`max-w-screen-xl w-full fixed top-2 left-1/2 z-50`,
  `transform: translateX(-50%);`
])
const WrapLangButtons = tw.div`flex p-4 absolute top-0 right-0 gap-x-2`
const LangButton = styled.button(({current}) => [
  tw`font-thin`,
  current && tw`font-bold`,
])

export const Header = () => {
  const { changeLang, lang } = useI18n()
  return (
    <Container>
      <WrapLangButtons>
        <LangButton
          current={lang === 'ja'}
          onClick={() => changeLang('ja')}
        >JP</LangButton>
        <span> / </span>
        <LangButton
          current={lang === 'en'}
          onClick={() => changeLang('en')}
        >EN</LangButton>
      </WrapLangButtons>
    </Container>
  )
}
