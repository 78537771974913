import React, {useState, useEffect} from 'react'
import { Helmet } from "react-helmet"
import tw, {styled} from 'twin.macro'
import {mdiClose} from "@mdi/js"
import Icon from '@mdi/react'
import Swal from "sweetalert2";
import {useHistory} from "react-router-dom"
import {db, collectionOptions, getDocsDate} from '../configs/firebase'
import {fetchReservationComplete} from "../api";
import {useI18n} from "use-mini18n"
import {useCollection} from "react-firebase-hooks/firestore";
import {useRecoilState} from "recoil"
import {selectPurchaseArtworkState} from "../store/app"
import {doc, collection, setDoc, addDoc} from "firebase/firestore"
import {flatMapDeep} from 'lodash-es'
import {ArtworkPurchase} from "../components/ArtworkPurchase";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import Modal from 'react-modal'
import {
  ArtworkImage1,
  ArtworkImage10,
  ArtworkImage11,
  ArtworkImage12,
  ArtworkImage13,
  ArtworkImage14,
  ArtworkImage15,
  ArtworkImage16,
  ArtworkImage17,
  ArtworkImage18,
  ArtworkImage19,
  ArtworkImage2,
  ArtworkImage20,
  ArtworkImage21,
  ArtworkImage22,
  ArtworkImage23,
  ArtworkImage24,
  ArtworkImage25,
  ArtworkImage26,
  ArtworkImage27,
  ArtworkImage28,
  ArtworkImage29,
  ArtworkImage3,
  ArtworkImage30,
  ArtworkImage4,
  ArtworkImage5,
  ArtworkImage6,
  ArtworkImage7,
  ArtworkImage8,
  ArtworkImage9
} from "../assets/artworkImage"
Modal.setAppElement('#root');
const COLLECTION_NAME = 'artwork'

const Container = tw.div`bg-gray-300 tracking-wider pb-20`
const Wrapper = tw.div`w-full max-w-screen-lg mx-auto px-5 md:px-20 pb-10 md:pb-20`
const WrapArtworks = tw.div`grid grid-cols-2 md:grid-cols-4 md:grid-rows-6 gap-x-2 md:gap-x-4 gap-y-2 md:gap-y-4`
const Controller = tw.div`fixed bottom-0 left-0 w-full h-20 md:h-24 flex justify-center p-4 bg-gray-900 items-center text-sm md:text-lg`
const ControllerInner = tw.div`flex gap-x-8 flex-row w-full max-w-screen-lg mx-auto`
const ButtonPurchase = styled.button(({disable}) => [
  tw`bg-yellow-300 py-2 px-3 rounded-full font-bold w-1/2 hover:bg-yellow-200`,
  disable && tw`bg-gray-300 hover:bg-gray-300 cursor-not-allowed`
])
const ButtonCancel = tw.button`bg-gray-300 py-2 px-3 rounded-full font-bold w-1/2 hover:bg-gray-200`
const ButtonClose = tw.button``
const Title = tw.h2`text-lg md:text-2xl font-bold pt-10`
const Desc = tw.p`text-sm md:text-base pt-6 pb-5 md:pb-10`
const ExternalLink = tw.a`text-blue-400 font-bold`
const InputText = tw.input`border-2 w-10 rounded`
const WrapReceipt = tw.div`py-6`
const WrapButton = tw.div`w-72 mx-auto`
const WrapClose = tw.div`absolute top-4 right-4 z-20`
const AnnotationText = tw.p`max-w-screen-sm mx-auto text-red-500 font-bold mt-4 text-center text-xs md:text-base`
const WrapPurchase = tw.div`flex justify-center mt-4 gap-x-4 w-11/12 mx-auto`
const ModalPurchase = tw.div`p-4 w-auto bg-white rounded-lg`
const CheckboxContainer = tw.label`mt-6 max-w-screen-sm mx-auto flex justify-center items-center`
const Checkbox = tw.input`mr-2`
const Link = tw.a`text-blue-400 hover:text-blue-200`

const ReceiptHeader = styled.ul(() => [
  tw`w-full flex font-bold text-xs md:text-base`,
  `> li {
    width: 25%;
  }`
])
const ReceiptContent = styled.ul(() => [
  tw`w-full flex text-xs md:text-base`,
  `> li {
    display: flex;
    align-items: center;
    border-top: 1px solid #cccccc;
    padding: 10px 0;
    width: 25%;
  }`
])
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


const Purchase = () => {
  const [selectedArtwork, setSelectedArtwork] = useRecoilState(selectPurchaseArtworkState)
  const {t, lang} = useI18n()
  const history = useHistory()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isPurchaseModal, setIsPurchaseModal] = useState(false)
  const [agreed, setAgreed] = useState(false)
  const [artworkCollection] = useCollection(collection(db, COLLECTION_NAME), collectionOptions)
  const artwork = getDocsDate(artworkCollection)
  useEffect(() => window.scrollTo(0, 0) ,[])
  const handleOpenPurchaseModal = () => {
    setIsOpenModal(false)
    setIsPurchaseModal(true)
  }
  const onApprove = async (data, actions) => {
    return await actions.order.capture();
  };
  const onComplete = async (result) => {
    if (result.status === 'COMPLETED') {
      const docRef = await addDoc(collection(db, 'receipt'), {
        create_time: result.create_time,
        id: result.id,
        order: flatMapDeep(selectedArtwork),
        payer: flatMapDeep(result.payer),
        amount: result.purchase_units[0].amount.value,
      })
      await fetchReservationComplete({
        id: docRef.id,
        order: selectedArtwork.map(item => {
          const _artwork = artwork?.filter(v => v.id === item.artwork)[0]
          const _currentPrice = _artwork ? parseInt(_artwork.price / (_artwork.amount + item.amount)) : 0
          const currentPrice = _currentPrice && _currentPrice > 0 ? _currentPrice : 1
          return {
            artwork: item.artwork,
            amount: item.amount,
            current_price: currentPrice,
          }
        }),
        total_apply: selectedArtwork.map(v => v.amount).reduce((a, c) => a + c),
        address: result.payer.email_address,
      })
    }
  }
  const handleChangeArtworkAmount = (num, amount) => {
    if (amount !== "") {
      const result = selectedArtwork.map(work => {
        if (work.artwork === num) {
          console.log(parseInt(amount) < 0)
          if (parseInt(amount) < 0) {
            return {
              artwork: num,
              amount: 0,
            }
          } else {
            return {
              artwork: num,
              amount: parseInt(amount),
            }
          }
        }
        return work
      })
      console.log(result)
      setSelectedArtwork(result)
    }
  }
  return (
    <Container>
      <Helmet>
        <meta property="og:image" content={`https://mf.3331.jp/ogp.jpg`} />
      </Helmet>
      <Wrapper>
        <Title>{t['purchase_title']}</Title>
        <Desc>
          <span>{t['purchase_desc']}</span>
          <br/>
          <br/>
          {
            lang === 'ja' ? (
              <>
                <span>購入前に参加規約をご確認頂き、ご同意の上、ご購入をお願いいたします。当作品購入の参加規約は<Link href="/terms" target="_blank" rel="noreferrer">こちら。</Link></span>
                <br/>
                <span>この作品の二次流通時の還元率は10%です。</span>
              </>
            ) : (
              <>
                <span>Please read and agree to the Terms of Participation before purchasing. Click <Link href="/terms" target="_blank" rel="noreferrer">here</Link> for the Terms of Participation.</span>
                <br/>
                <span>The Royalty rate for secondary distribution of this work is 10%.</span>
              </>
            )
          }
        </Desc>
        <WrapArtworks>
          <ArtworkPurchase src={ArtworkImage1} num={"001"} title={"tmp"} artwork={artwork} />
          <ArtworkPurchase src={ArtworkImage2} num={"002"} title={"background"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage3} num={"003"} title={"DONDONs3"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage4} num={"004"} title={"body2"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage5} num={"005"} title={"swapper"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage6} num={"006"} title={"xx"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage7} num={"007"} title={"DONDONs"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage8} num={"008"} title={"body4"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage9} num={"009"} title={"mask2"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage10} num={"010"} title={"tileman"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage11} num={"011"} title={"telephone"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage12} num={"012"} title={"kappa-V2"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage13} num={"013"} title={"kappa2"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage14} num={"014"} title={"kappas"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage15} num={"015"} title={"kappa"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage16} num={"016"} title={"Ekubo30"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage17} num={"017"} title={"Ekubo00"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage18} num={"018"} title={"Ekubo20"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage19} num={"019"} title={"xx"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage20} num={"020"} title={"pokan"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage21} num={"021"} title={"kabu"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage22} num={"022"} title={"lionbody4"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage23} num={"023"} title={"face"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage24} num={"024"} title={"suuji-mask"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage25} num={"025"} title={"lion5"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage26} num={"026"} title={"axis"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage27} num={"027"} title={"Gas"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage28} num={"028"} title={"DONDONs2"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage29} num={"029"} title={lang === 'ja' ? "リフレクター" : "Reflector"} artwork={artwork}  />
          <ArtworkPurchase src={ArtworkImage30} num={"030"} title={"tmp"} artwork={artwork}  />
        </WrapArtworks>
      </Wrapper>
      <Controller>
        <ControllerInner>
          <ButtonCancel onClick={() => history.push('/')}>{t['thanks_top']}</ButtonCancel>
          <ButtonPurchase
            disable={!(selectedArtwork.length > 0)}
            onClick={() => selectedArtwork.length > 0 && setIsOpenModal(true)}
          >{t['continue_purchase']}</ButtonPurchase>
        </ControllerInner>
      </Controller>
      <Modal
        isOpen={isOpenModal}
        style={{overlay: {backgroundColor: 'rgba(0, 0, 0, 0.75)'},}}
      >
        <WrapClose>
          <ButtonClose onClick={() => setIsOpenModal(false)}>
            <Icon path={mdiClose} size={1} />
          </ButtonClose>
        </WrapClose>
        <ReceiptHeader>
          <li>{t['purchase_num']}</li>
          <li>{t['purchase_price']}</li>
          <li>{t['purchase_amount']}</li>
          <li>{t['purchase_subtotal']}</li>
        </ReceiptHeader>
        <WrapReceipt>
          {
            selectedArtwork.length > 0 && selectedArtwork.map(item => {
              const _artwork = artwork?.filter(v => v.id === item.artwork)[0]
              const _currentPrice = _artwork ? parseInt(_artwork.price / (_artwork.amount + item.amount)) : 0
              const currentPrice = _currentPrice && _currentPrice > 0 ? _currentPrice : 1
              return (
                <ReceiptContent key={item.artwork}>
                  <li>{item.artwork}</li>
                  <li>{`${currentPrice.toLocaleString()}${t['work_price_unit']}`}</li>
                  <li><InputText type="number" defaultValue={item.amount} onChange={(e) => handleChangeArtworkAmount(item.artwork, e.target.value)} /></li>
                  <li>{item.amount !== '' ? `${(currentPrice * item.amount).toLocaleString()}${t['work_price_unit']}` : '-'}</li>
                </ReceiptContent>
              )
            })
          }
          <ReceiptContent>
            <li>{t['purchase_fee']}</li>
            <li>{`1,100${t['work_price_unit']}`}</li>
            <li>{selectedArtwork.length > 0 && selectedArtwork.map(v => v.amount).reduce((a, c) => a + c)}</li>
            <li>{`${selectedArtwork.length > 0 && (selectedArtwork.map(v => v.amount).reduce((a, c) => a + c) * 1100).toLocaleString()}${t['work_price_unit']}`}</li>
          </ReceiptContent>
          <ReceiptContent>
            <li>{t['purchase_sum']}</li>
            <li />
            <li>{selectedArtwork.length > 0 && selectedArtwork.map(v => v.amount).reduce((a, c) => a + c)}</li>
            <li><b>
              {
                selectedArtwork.length > 0 && artwork &&
                (selectedArtwork.map(v => {
                  const _artwork = artwork.filter(work => work.id === v.artwork)[0]
                  const _currentPrice = _artwork ? parseInt(_artwork.price / (_artwork.amount + v.amount)) : 0
                  const currentPrice = _currentPrice && _currentPrice > 0 ? _currentPrice : 1
                  return v.amount * currentPrice
                }).reduce((a, c) => a + c) + (selectedArtwork.map(v => v.amount).reduce((a, c) => a + c) * 1100)).toLocaleString()
              }
            </b>{t['work_price_unit']}</li>
          </ReceiptContent>
          <AnnotationText>
            {t['purchase_notice1']}<br/><br/>
            {t['purchase_notice2']}: <b>{`${selectedArtwork.length > 0 && (selectedArtwork.map(v => v.amount).reduce((a, c) => a + c) * 1100).toLocaleString()}${t['work_price_unit']}`}</b><br />
            {t['purchase_notice3']}
          </AnnotationText>
          <CheckboxContainer>
            <Checkbox type="checkbox" checked={agreed} onChange={(e) => setAgreed(e.target.checked)}/>
            {
              lang === 'ja' ? (
                <p>
                  <ExternalLink href="/terms" target="_blank" rel="noreferrer">利用規約</ExternalLink>と
                  <ExternalLink href="https://mf.3331.jp/211209_artwork_term_jp_en.pdf" target="_blank" rel="noreferrer">作品規約</ExternalLink>に同意します。
                </p>
              ) : (
                <p>I Agree to <ExternalLink href="/terms" target="_blank" rel="noreferrer">{t['terms_title']}</ExternalLink> and <ExternalLink href="https://mf.3331.jp/211209_artwork_term_jp_en.pdf" target="_blank" rel="noreferrer">{t['works_term']}</ExternalLink>.</p>
              )
            }
          </CheckboxContainer>
          <WrapPurchase>
            <ButtonCancel onClick={() => setIsOpenModal(false)}>{t['purchase_cancel']}</ButtonCancel>
            <ButtonPurchase disable={!agreed} onClick={() => {
              if (agreed) handleOpenPurchaseModal()
            }}>{t['purchase_pay']}</ButtonPurchase>
          </WrapPurchase>
        </WrapReceipt>
      </Modal>
      <Modal
        isOpen={isPurchaseModal}
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)'},
          content: { backgroundColor: 'transparent', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }
        }}
      >
        <ModalPurchase>
          <ButtonClose onClick={() => setIsPurchaseModal(false)}>
            <Icon path={mdiClose} size={1} />
          </ButtonClose>
          <WrapButton>
            <PayPalScriptProvider options={{
              "client-id": "ASOXa2R9bDolgO_WpHjh488nR69xwHRcsklKiADyJqaFq2MsNpNoOAs3Vw78MYOe5DAT9QJG0CAJuyO1",
              // "client-id": "test",
              "currency": "JPY"
            }}>
              <PayPalButtons
                style={{ layout: "horizontal" }}
                createOrder={(data, actions) => {
                  console.log(selectedArtwork.map(v => v.amount).reduce((a, c) => a + c) * 1100)
                  console.log(selectedArtwork)
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: selectedArtwork.map(v => v.amount).reduce((a, c) => a + c) * 1100
                        },
                      },
                    ],
                  });
                }}
                onApprove={async (data, actions) => {
                  const approve = await onApprove(data, actions)
                  console.log(approve)
                  if (approve) {
                    await onComplete(approve)
                    history.push('/complete')
                    setIsPurchaseModal(false)
                    setSelectedArtwork([])
                    await Toast.fire({
                      icon: 'success',
                      title: t['purchase_success'],
                    })
                  }
                }}
              />
            </PayPalScriptProvider>
          </WrapButton>
        </ModalPurchase>
      </Modal>
    </Container>
  )
}

export default Purchase
