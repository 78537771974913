import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

export const collectionOptions = {
  snapshotListenOptions: {
    includeMetadataChanges: true
  }
}

export const getDocsDate = (snapshot) => {
  if (snapshot) return snapshot.docs.map(v => v.data())
}

const firebaseConfig = {
  apiKey: "AIzaSyCqu8TIJfghW8r41g7qqrSSlb3jJfNf8C4",
  authDomain: "bnc-web.firebaseapp.com",
  projectId: "bnc-web",
  storageBucket: "bnc-web.appspot.com",
  messagingSenderId: "834398688434",
  appId: "1:834398688434:web:a9c695e93a01444c9e25ab"
};

initializeApp(firebaseConfig);

export const db = getFirestore();
