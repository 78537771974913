import React, { useRef, useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import { TransProvider } from 'use-mini18n'
import { i18n } from './configs/i18n'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Home, Purchase, Complete, Terms, Artwork } from './pages'
import 'sweetalert2/dist/sweetalert2.css'

const App = () => {
  const router = useRef(null)

  useEffect(() => {
    router.current.history.listen((location) => {
      window.gtag('config', 'G-NSNP4FRZSC', {
        'page_path': location.pathname
      })
    })
  })

  return (
    <Router ref={router}>
      <RecoilRoot>
        <TransProvider i18n={i18n}>
          <Header />
          <Switch>
            <Route exact path="/"><Home /></Route>
            <Route exact path="/purchase"><Purchase /></Route>
            <Route exact path="/complete"><Complete /></Route>
            <Route exact path="/terms"><Terms /></Route>
            <Route path="/artwork/:id"><Artwork /></Route>
          </Switch>
          <Footer />
        </TransProvider>
      </RecoilRoot>
    </Router>
  );
}

export default App
