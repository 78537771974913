import React from 'react'
import tw from 'twin.macro'
import {useHistory} from 'react-router-dom'
import {useI18n} from "use-mini18n"

const Container = tw.div`w-full h-screen flex flex-col items-center justify-center text-lg font-bold`
const WrapButton = tw.div`mt-4 mx-auto`
const LinkPurchase = tw.button`w-full font-bold h-16 flex items-center justify-center bg-yellow-300 hover:bg-yellow-200 rounded-full px-20`

const Complete = () => {
  const {t} = useI18n()
  const history = useHistory()
  return (
    <Container>
      <p>Payment in successfully</p>
      <p>Thank you for joining!</p>
      <WrapButton>
        <LinkPurchase onClick={() => history.push('/')}>{t['thanks_top']}</LinkPurchase>
      </WrapButton>
    </Container>
  )
}

export default Complete
