export const artwork = [{
  "amount": 912,
  "price": 1000000,
  "id": "001"
},{
  "amount": 5,
  "id": "002",
  "price": 500000
}, {
  "amount": 7,
  "id": "003",
  "price": 330000
}, {
  "amount": 4,
  "id": "004",
  "price": 200000
}, {
  "amount": 3,
  "price": 330000,
  "id": "005"
}, {
  "price": 500000,
  "amount": 8,
  "id": "006"
}, {
  "price": 100000,
  "amount": 8,
  "id": "007",
}, {
  "id": "008",
  "price": 200000,
  "amount": 4
}, {
  "price": 100000,
  "amount": 3,
  "id": "009"
}, {
  "id": "010",
  "amount": 5,
  "price": 200000
}, {
  "price": 100000,
  "id": "011",
  "amount": 31
}, {
  "id": "012",
  "price": 200000,
  "amount": 5,
}, {
  "id": "013",
  "amount": 6,
  "price": 200000
}, {
  "price": 200000,
  "id": "014",
  "amount": 5
}, {
  "id": "015",
  "amount": 6,
  "price": 200000
}, {
  "price": 200000,
  "amount": 5,
  "id": "016"
}, {
  "price": 200000,
  "amount": 6,
  "id": "017"
}, {
  "id": "018",
  "price": 200000,
  "amount": 3
}, {
  "amount": 4,
  "id": "019",
  "price": 500000,
}, {
  "id": "020",
  "price": 500000,
  "amount": 3
}, {
  "amount": 5,
  "price": 500000,
  "id": "021"
}, {
  "amount": 7,
  "price": 100000,
  "id": "022"
}, {
  "amount": 6,
  "price": 100000,
  "id": "023"
}, {
  "id": "024",
  "amount": 4,
  "price": 100000
}, {
  "price": 100000,
  "amount": 7,
  "id": "025",
}, {
  "price": 330000,
  "id": "026",
  "amount": 5,
}, {
  "id": "027",
  "amount": 10,
  "price": 100000
}, {
  "id": "028",
  "amount": 6,
  "price": 100000,
}, {
  "id": "029",
  "amount": 14,
  "price": 330000
}, {
  "amount": 15,
  "id": "030",
  "price": 1000000
}]
