import React from 'react'
import { Helmet } from "react-helmet"
import tw from 'twin.macro'
import {useI18n} from "use-mini18n"

const Container = tw.div`bg-gray-300 tracking-wider`
const Inner = tw.div`w-full max-w-screen-lg mx-auto px-5 md:px-20 pb-10 md:pb-20`
const Title = tw.h2`text-lg md:text-2xl font-bold pt-10 mb-4`
const Sign = tw.p`w-full text-right mt-4 mb-8 whitespace-pre-wrap`
const Desc = tw.p`mt-4 whitespace-pre-wrap`
const Link = tw.a`text-blue-500 hover:text-blue-300 cursor-pointer`

const Terms = () => {
  const {t} = useI18n()
  return (
    <Container>
      <Helmet>
        <meta property="og:image" content={`https://mf.3331.jp/ogp.jpg`} />
      </Helmet>
      <Inner>
        <Title>{t['terms_title']}</Title>
        <Desc>{t['terms_desc1']}</Desc>
        <Desc>{t['terms_desc2']}</Desc>
        <Desc>{t['terms_desc3']}</Desc>
        <Desc>{t['terms_desc4']}</Desc>
        <p><Link href="https://mf.3331.jp/211209_artwork_term_jp_en.pdf" target='_blank' rel="noreferrer">{t['works_term']}</Link></p>
        <Desc>{t['terms_desc5']}</Desc>
        <Desc>{t['terms_desc6']}</Desc>
        <Desc>{t['terms_desc7']}</Desc>
        <Desc>{t['terms_desc8']}</Desc>
        <Desc>{t['terms_desc9']}</Desc>
        <Desc>{t['terms_desc10']}</Desc>
        <Desc>{t['terms_desc11']}</Desc>
        <Sign>{t['terms_sign']}</Sign>
      </Inner>
    </Container>
  )
}

export default Terms
