export const i18n = {
  ja: {
    hero_title: '「Brave New Commons」は、作品の価値について考える。',
    hero_date: '2021年10月24日  藤幡正樹',
    hero_desc: '「Brave New Commons」は、NFTを利用することで可能となるデジタルデータ作品の所有を、従来型の物質に根ざした唯一性ではなく、デジタルデータの特性を活かした新しい所有のあり方として考えるプロジェクトです。ひとつは「Subdivision方式」と呼ぶもので、作品価格を作家が任意に決定した価格を購入者数で割り算することで決定します。結果、購入者数が多い作品ほど最終価格は低くなります。また、デジタルデータにオリジナルと複製の区別がないことから、これは結果的に複数の所有者が、まったく同じ作品を持つことになるので、いわゆる作品の「共同所有」とはことなった形態、「分散所有」と呼ぶべき形態を取ります。',
    ars_text: '2020年9月7日～11日に開催されるArs Electronica Festivalでは、2021年10月に3331アートセンターで開催された「符号理論」展の会場再現と、さらに発展・派生した2つの新しいプロジェクトを紹介します。',
    anno_desc1: '一点あたり1000円（＋消費税）の参加費でご参加いただけます。申し込みは1月31日が最終締切となります。',
    anno_desc2: 'PayPalによる支払い方法のみの受付となりますので、PayPalアカウントの取得が必要条件となります。また、PayPalアカウントに登録されている電子メールアドレスが、NFT譲渡時の受け取りアドレスとなりますことにご注意ください。',
    anno_desc3: '購入ページにて、複数の作品の同時購入申し込み、また単一作品の複数購入申し込みが可能です。',
    pj_title: 'プロジェクト参加の仕組み',
    pj_desc1: '以下の上げた過去の未発表デジタル作品が対象です。いわば、価値があってもデジタルなので売りようのなかった作品です。今回は1985年頃に作られた未発表のMacPaintで作られた画像から選びました。',
    pj_desc2: 'これに販売者（藤幡）が元値を設定しました。',
    pj_desc3: '購入希望者を3331アートフェアとウェブ両方から募集します。フェア終了後に参加者数で元値を割り算したものが購入者の払う金額になります。参加者が多いほど、一人当たりの値段は下がる仕組みです。参加者はより多くの参加者に声をかける。と、もっと下がるということになりますので、友人にドンドン進めてください。みんなが欲しがる作品ほど安くなってゆくでしょうし、人気がないと高いままになるのではないかと、。',
    pj_desc4: 'アートフェア期間中は、会場にて参加申し込み可能、会期後ウェブでの参加申し込みに移行します。',
    pj_desc5: '募集締め切り後、参加者数＝エディション数の形でNFTを発行。振り込み確認後に、藤幡から各購入者へ作品を譲渡されます。',
    pj_desc6: 'コモンズの誕生です。',
    works_title: '出展作品',
    work_title: 'タイトル',
    work_num: '作品番号',
    work_date: '作成日',
    work_price: '現在価格',
    work_price_unit: '円',
    work1_date: '1989年1月27日 金曜日 0:02',
    work1_desc: '極めて希少価値の高い、明らかな書き損じである。そもそもこのファイルが 30 年以上も生き延びたこと自体が奇跡であろう。そして明らかな当時のボールマウスの軌跡。',
    work2_date: '1989年1月27日 金曜日 1:54',
    work2_desc: 'なんの背景に使ったのかは、今では不明であることは貴重。図像の意味を純粋に考えさせられる。上から下に引かれた線と単純に想像できるが、本当だろうか、ここで使われた描画器具はマウスであって、筆ではない。筆に見えるのは似たような仕組みがあったはずだからだ。下部に一本の水平線あり。',
    work3_date: '1995年6月3日 土曜日 11:29',
    work3_desc: '画面内に「Masaki Fujihata April 1985」の記述あり。制作から十年後にコピーをとった時に日付が更新されたということか。完成度がやけに高い。大きく、ピクセルがシャープに真四角に出るようにプリントされると良い。 関連ファイル: 007, 028',
    work4_date: '1989年1月17日 火曜日 23:27',
    work4_desc: 'MacPaint に "Trace Edges" というコマンドがあって、ずいぶん遊んだ。一本の線の両側にもう一本線を引くというコマンドだが、確かメニューを使わなくてもキー操作で出来るので、どんどん輪郭線を増やしていくとそれだけでアニメーションのようで楽しい機能である。関連ファイル: 008',
    work5_date: '1995年6月3日 土曜日 11:30',
    work5_desc: '"Masaki FUJIHATA Apr. 1985"。だいたい "Trace Edges" というコマンドを使っていると意味が崩壊してゆき、エントロピーが高まる。当時はハードディスクが無かったので、途中経過はどんどん捨てていたんだろう。関連ファイル: 003, 026',
    work6_date: '1989年1月27日 金曜日 1:50',
    work6_desc: '下部のノイズは僕が描いたのではなく、どこかの段階で入ったものだ。普通デジタル環境では、たったひとつのエラーで全部が読めなくなってしまうはずなのだが、破壊はされずにデータが生き残っているので非常に貴重。また、それを予言したかのようなイラスト。これを捨てずに取っていたのは、奇跡。',
    work7_date: '1995年6月3日 土曜日 11:29',
    work7_desc: '003、008 と同じフォルダーに入っていたので、003 の DONDONs3 の習作であると考えられるが、その部品というわけでもなさそうで、独立しているようにも見える。関連ファイル: 003, 028',
    work8_date: '1989年1月18日 水曜日 21:34',
    work8_desc: '004 が、"trace edges" のヤリ過ぎで飛んでいるのに対して、こちらはヘロヘロという感じ。関連ファイル: 004',
    work9_date: '1985年2月23日 土曜日 4:49',
    work9_desc: '制作の意図は不明。ファイル名から察するに仮面なんだということは判るし、ちょっと縄文が入っている。しかし、この平行線は一本づつ描いたものではなく、trace edges で自動的に作ったものだ。',
    work10_date: '1995年6月3日 土曜日 11:30',
    work10_desc: 'マックを使ったことのある人であれば当時の重たいボールマウスを思い出して欲しい。これはいたずら描きではない。デジタル・アニミズム。関連ファイル: 023',
    work11_date: '1985年9月12日 木曜日 6:45',
    work11_desc: '黒電話である。発見、デジタルと黒電話は共存していた。受話器の黒さ、重さ、留守番電話の声などが思い出される。',
    work12_date: '1985年12月15日 日曜日 16:47',
    work12_desc: 'フロッグスの連載を見て、蛙じゃなくて河童をお願いしますと言われた記憶があるので、これはちょっと不純な絵画だが、今見てもキャラがあって売れそうだ。012、013、014、015 は、4枚セットで買っていただく価格設定にしました。是非セットで買ってください。',
    work13_date: '1985年11月24日 日曜日 0:12',
    work13_desc: '貴重な描きかけ。この画像は 015 と同じフロッピーに入っていた。012 と 014 はフロッピーではなくすでにフォルダーに入っていて、この 2 枚とは別立てである。関連ファイル: 012, 014, 015',
    work14_date: '1985年11月24日 日曜日 18:26',
    work14_desc: '012 とこのファイルの画像は、他の 2 枚と比べて整理されている。関連ファイル: 012, 013, 015',
    work15_date: '1985年11月20日 水曜日 1:46',
    work15_desc: '黒河童たち。Macintosh のディスプレイは各ピクセル 1 ビットの深さしかないので、黒いか白いかしかない。拡大すると階段状のピクセルが現れる。これがとても新鮮だった。拡大して縮小しても元には戻らない。関連ファイル: 012, 013, 014',
    work16_date: '1988年11月29日 火曜日 18:37',
    work16_desc: '今回もっとも発見のあった画像。楕円だけでできている。MacPaint の楕円は縦方向と横方向しか作れないので、斜めの楕円は作れない。その大きさだけで、まるでワイヤーフレームでできた CG のようなものを作っているのはおもしろい。016、017、018、3 枚セットで購入して欲しい。',
    work17_date: '1988年11月29日 火曜日 18:22',
    work17_desc: '関連ファイル: 016, 018',
    work18_date: '1988年11月29日 火曜日 18:36',
    work18_desc: 'これを見ると、MacPaint の使い手としては、そうとうなレベルにあったんじゃないでしょうか、非常に新鮮。',
    work19_date: '1989年2月14日 火曜日 19:38',
    work19_desc: '元はイラストレータファイルなので、購入いただく実ファイルでは、背景がアルファチャンネルで抜けている。周りの線は赤。タイトルが「XX」であるように、テンポラリーなファイルなのだろうが、発掘画像として「かなり 最高」なんじゃないだろうか。関連ファイル: 020, 021',
    work20_date: '1989年1月27日 金曜日 1:10',
    work20_desc: 'もともとの作成はイラストレータだが、ビットマップに変換されているので、背景はアルファで抜けていない。この頃初めてイラストレータを真剣に使ってみた頃で、その習作だと思われる。関連ファイル: 019, 021',
    work21_date: '1989年2月21日 火曜日 17:29',
    work21_desc: 'これも元はイラストレータ・ファイル。印刷でグレーに見える線は緑色。「kabu」というのはいったいどこから来たのか不明。30 年の時間がいとおしい。関連ファイル: 019, 020',
    work22_date: '1995年6月3日 土曜日 11:30',
    work22_desc: 'このライオンをめぐる画像ファイルがかなり残っている。デッサン風のものから徐々にキャラクターっぽくなる過程が残っている。その中でこのファイルは顔だけ切り抜いて別立てになっている。関連ファイル: 025',
    work23_date: '1985年9月6日 金曜日 19:50',
    work23_desc: '顔。もうまったく不明。なにかのために描いたのではないことは確か。010 のいたずら描きと似ているが日付の照合ができないので、不明。記憶をたどると 3663 という数字を書いてから、それを無理やり顔にしたのではないだろうか。関連ファイル: 010',
    work24_date: '1986年4月16日 水曜日 12:26',
    work24_desc: '題名が「数字マスク」なので、「株式会社フロッグス」の習作だと思われる。「株式会社フロッグス」関連の画像は、今回ほぼ発掘された。当時はデジタルでの入稿ではなかったので、プリントアウトを台紙に貼っていたために、こういう画像も切り抜いて使った可能性あり。',
    work25_date: '1995年6月3日 土曜日 11:29',
    work25_desc: 'デジタルのひとつのアドバンテージは、バリエーションをいろいろ試せるところである。他にもバリエーションがあるが、今回は載せなかった。関連ファイル: 022',
    work26_date: '1995年6月3日 土曜日 11:29',
    work26_desc: '“Masaki Fujihata Feb. 1985”の表記。trace edges とパターンの多様である。パターンはグレーの階調もカラーも無かったので、平面を塗りつぶす方法がこれしか無かったのである。関連ファイル: 005',
    work27_date: '1986年3月14日 金曜日 3:09',
    work27_desc: 'やっぱり顔を描くのはたのしいらしい。編集者にも「日本のディズニーになれるなあ、」とか言われたものだ。タイトルの「Gas」というのが良い。',
    work28_date: '1995年6月3日 土曜日 11:29',
    work28_desc: 'この 3 枚には、共通のタイトル「DONDONs」というのが付いている。主人公の名前なのか、書いている時の雰囲気からのネーミングだろうか。この 3 枚もセットで買って欲しいものだ。関連ファイル: 003, 007',
    work29_date: '1990年6月6日 水曜日 21:33',
    work29_desc: '1989 年頃に、僕は「コンピュータは Mind Reflector」だという持論を、あちこちで吹いていた。その解説の図を適当に描いたものを同席していた方が写真に撮ってくれて、だいぶ経ってからトレースしたイラストレータファイルをくれた。潔く省略された線描が心地よい。書かれてあった文字はフォントがなくてまったく読めないものに変換されている。歴史的で奇跡的な抽象絵画である。背景はアルファで抜けている。',
    work30_date: '1995年6月3日 土曜日 11:35',
    work30_desc: 'またもやファイル名が「tmp」。で、もって作成中の「自慰行為」のロゴ。目的用途不明。買われた方は額縁に入れて飾って欲しい。',
    state1_1: '1\n' +
      '\n' +
      '　今回、販売の対象とする作品は、すべて未発表作品。藤幡がMacintosh computer上のMacPaintソフトウエアを使って1980年代に作成した画像データだ。これらは、35年ほど前に作られたデータで、ハードディスク、400Kや800Kのフロッピーディスク上に残されていたデータを数週間かかって救出してきたのだ。これらの作品はデジタルの複製技術環境で作られたもので、黄ばむこともなくひび割れることもなく、理念的にはなにも加工されていない「生絵画」であり、さっき描かれたばかりのように新鮮だ。Macintoshコンピュータの当初のインターフェイスはWYSWYG(What you see is what you get)という考え方で設計されていて、画面は72ドット/インチの解像度で384x512ドット、この大きさがそのままディスプレイサイズだった。MacPaintは開くと、ちょうど8x10インチのレターサイズ（横幅8インチ縦10インチ＝576x720ドット）の用紙が用意されていて、そのうちの１部分が窓を通して見えていた。プリントアウトすると、このレターサイズのままのプリントがでてくる。現実世界とコンピュータ内の世界の関係にアップル社はものすごく気を使っていたのである。\n' +
      '　僕自身は、1982年にあるコンピュータ・グラフィックスの制作会社の立ち上げに関わり、なんと当時は「CGアーティスト」という肩書で呼ばれていた。この会社はコンピュータ機材に3億円の投資をして立ち上げた会社で、システムはカスタムメイド、ソフトウエアもスクラッチからメンバーのプログラマーが書いた。そこへ突如登場したパーソナルコンピュータがMacintoshで、僕は即座に購入して家で使っていた。使うといってもMacPaintとMacWriteしかないのだから、普通にコンピュータを仕事で使おうと考える人は気にも止めていなかった。買っていた人たちは、よそで見たことのない未来を垣間見るのが好きな人たちで、アップルショップはそういう人がいっぱいで、すぐに仲良くなったものだ。',
    state1_2: '　そんなこんなで、人に会ってはMacintoshの楽しさを熱く語っていたのだが、あんまり熱く語ったためなのか、ある編集者に「じゃイラストレーションの連載やれば、」という話になり、僕は突如「イラストレーター」になった。それがブルータスに連載していた「株式会社フロッグス」だ。このイラストレーションは、それなりに影響力があったようで後にいろいろな人から刺激を受けたと告白された。しかし、それは僕だけがやったことではなく、Macintoshが垣間見せてくれた未来を、僕が単にイラストレーションに落としたに過ぎないのかもしれない。\n' +
      '　このMacPaintの革新性については語りだすとキリがない。これを使うということは、筆記用具を根本的なところから見直す作業に近かったからだ。筆記用具が持つ制約が紙や鉛筆とは違っているので、その制約の中で新しい書き順を考案しなくてはならない。それが刺激的だったのだ。いわば、レゴブロックで習字をやるような、、とでも言っておこうか。この一連の画像はそういったデジタル的な制約の中にあるもので、その制約が見えてくるとおもしろく見えてくるはずである。',
    state2_1: '　35年前の未発表作品ということは、誰かに頼まれて描いたわけではないので、とても純粋な動機で描かれた絵画である。とはいえ、これらはこれまでは顧みられて来なかった、すっかり忘れさられた作品なので、いわばゴミ箱の中にあったものを探し出して救助したに近い作品である。こんなものが売れるんだろうかと不安もある。この価値をどう読み解くかは、まずは作った自分には非常に難しい。というわけで、このプロジェクトでは、この価値をいっしょに考えて貰うということを目的としている。\n' +
      '\n' +
      '\n' +
      '2\n' +
      '\n' +
      '　具体的には、作品に価格をみんなで与えるということを考えたのだ。しかし、そもそも価格というのはなんなのだろうか？本来の消費財の価格というのは、製造価格＋流通＋店舗のマージンで決まるものだという。そこで、メーカーは製造価格をできるだけ下げて競合他社に勝つために汗をかいている。これに対して美術品といったコレクションの対象となるような物品の価格は、その唯一性から所有者がひとりに限られてしまうために、所有したい人が増えると価格は自然と上がる。',
    state2_2: '20世紀初頭に、「この唯一性、物体の一回性が権威を生む」と言ったのは、W.ベンヤミンだ。彼は「複製技術時代の芸術」の中で、この一回生のことを「アウラ」と呼んで、アウラと関わらなくて済む映画や写真という、これから生まれる複製技術時代の芸術を称揚したのである。\n' +
      '　しかし、皮肉なことだが、実際に戦後から現代にかけて起こったことは、複製技術を用いた作品でも「アウラ」を持つことができることを証明してゆくという歴史だった。つまり、複製技術を使った作品でも「一回性」を偽装でき、それに権威を与えることが可能だとしたのだ。それは本来の権威とは逆方向の作用によって証明された。つまり「所有したい」という欲望を先に喚起させることで、多く売れる現実を先に作り出しそれを権威にしたのだ。これは美術業界以前にファッションブランドがやってきたことである。結果、起こったことは、多く売れる、高く売れる作品が権威となったわけだ。',
    state3_1: '3\n' +
      '\n' +
      '　現在の社会経済構造の背後には、これまでの資本家と労働者＝消費者というロールプレーヤー以外に、第3のロールプレーヤーとしての投資家の存在が付け加わっている。従来の資本家は、余剰資産を工場設備等の新規化のための投資に使うが、投資家は投資すること自体を仕事にしている人のことだ。彼らの行動、売り買い情報が結果的に企業価値の高騰や暴落に影響を与えることはすでに日常化している。お金が生む情報が、お金を捻じ曲げてしまうのだ。投資家は常にその動向を観察しているので、その投資マーケットで起こっていることは、われわれの日常の生活とは乖離している。ここでの出来事は、われわれの日常的な物価状況とはまったく関係なく上がったり下がったりし、さらに国を越えて影響を及ぼすことになる。\n' +
      '\n' +
      '　ところが、この余剰資産の行き先のひとつとしてアートマーケットが近年急に浮上してきた。これまでは美術の話題は美術館や画廊がプレーヤーだったのだが、最近はアートバーゼルを始めとする「アートフェア」に中心が移っている。',
    state3_2: 'その背景には、こうした投資家の存在があり、彼らは余剰資産の行き先として美術品がもっとも安全だと考えているのである。安全というのは、それらの作品の価値が、巨大な共同体によって保証されているからだ。それは言い換えると「歴史」と「文化」ということである。文化というのは、その共同体の背後にあるアイデンティティーであり、その共同体が存在する限りその価値は大きく変動しないと考えられるからだ。言ってみれば、法隆寺は日本文化の一部であり、その価格はほぼ無限大に高いために、すでに誰も買うことができないのである。しかし、文化が余剰資産の投資先になって良いのだろうか？これは本来はあり得ないことであって、今後大きな問題となっていくのではないかと考える次第である。',
    state4_1: '4\n' +
      '\n' +
      '　ところで、情報産業では価値と価格はどのようにコントロールされてきたのだろうか。70年代から80年代、ソフトウェアも物の販売と同じスキームで売られていた。パッケージに入れられて専門店で購入、あるいはカスタムメイドで発注して、工作機械のように購入し、減価償却の対象にもなっていた。しかし、最近は利用者には所有させずに、ソフトウェアの使用権を販売するサービスに移行しつつある。いや、もしかしたら昔から僕らは購入して所有していたのではなくて、単純に「使わさせていただいていた」だけかもしれない。次のような議論がある。本来、購入して自分の所有物にするということは、改編の可能性（壊してしまうことも含めて）を買うということだ、というものだ。買ったナイフの握り手部分を自分の手の形に合わせて削るのは常識だ。この改編可能性については、現在のフリーソフトウェア・ムーブメントはいい形で拾ってくれている。改編可能性を保証するソフトウエアの配布方法がすでに実装されている。かつて、ソフトウエアは特許か著作権かという議論があったが、特許ではなく著作としたのは、こうした意味で文書の引用可能性と同じ機能を、ソフトウエアが保持している必要があるからであって、これは正しい判断だったといえる。\n' +
      '\n' +
      '　特に80年代から90年代にかけては、「著作権を放棄しないものの、他者に自由に使わせることを認める」という「コピーレフト」を提唱したR.ストールマンのGNU(GNU is not Unix)や、ソフトウエアを公共財と考えるPublic Domain Softwareといった動きがあった。こうした運動の背後には、UnixのライセンスをめぐってATTとアカデミズムとの戦いなどがあり、所有、使用、プログラマーVSユーザーをめぐるさまざまな議論があったからだ。そう言えば、PDSという言葉を初めて知ったのはアップルショップであった。お店に大量のPDSソフトウエアの入ったフロッピーディスクが並んでいて、フロッピーディスクを買えば、好きなソフトをフロッピーに入るだけコピーして構わないのだ。この感じはまるで、駄菓子屋だ。実際には、中身は作りかけのソフトウエアが多く、大概は爆弾が出てリブートしなくてはならないようなものだったが、さまざまなアイデアに触れることができて、とても刺激を受けたものである。ところが、当時のこうした社会的なシェアに向けた動きに対して、アップル社はマッキントシュの発売以降、自らが育てた熱狂的なソフトウエア・プログラマーコミュニティーには比較的冷たく、製作者と利用者の間に壁を立ててゆくことになった。コンピュータを巡るコミュニティ、いわばコモンズと商品としてのコンピュータを分離することが、彼らのビジネスを大きくしてゆく上で必要であると判断したのであろう。',
    state5_1: '5\n' +
      '\n' +
      '　バブルまっさかりの頃、1990年に大昭和製紙の斉藤了英会長が、ゴッホを約125億4000万円で落札。さらにその2日後ルノアールの絵画を約119億円で落札した。彼は2枚の名画を『死んだら棺桶に一緒に入れて焼いてくれ』と言ったとされ、世界中のアートコレクターから顰蹙を買った。この話は歴史的な文化遺産級の絵画を所有していても、改編不可能だということを社会に知らしめることになった。本人は「庶民受けする軽口だった」としているが、軽口とはいえそんな発想が普通に浮かんでくるところに、彼が美術品も一般消費財と同じじゃないか、と考えていることがよく分かるエピソードだった。こうした美術品は、オークションで売られているとはいえ、いわゆる消費できる商品ではないのである。価格が大きいだけで、一時的な使用権を買っているだけなのである。ということは、アーティスト、あるいは画廊も含めたアートマーケットというのは、作品を消費対象の商品の外側のゾーンに持ってゆく努力をする人たちのことを指すことになる。このゾーンというのは、いわば「所有できないものを作る」ということだ。',
    state6_1: '6\n' +
      '\n' +
      '　デジタル作品というのは、フィルム焼付現像のような複製技術によって作られるものではなく、複製技術そのものが作品になっているという創作物のことで、ディスプレイやスピーカーなどデータを知覚可能な対象物に変換する装置によって、はじめて人が見ることのできるようになる作品のことである。ウェブサイトでイメージを見ているというのは、ディスプレイ上にコピーされたデータを見ているということだ。いくらでもコピーできるということは、はじめから供給過多状態にあることであり、価格はどんどん安くなってしまう。今ではソフトウエアはデータ収集のインフラであり、フリーでばら撒かれるもので、多くの人はソフトウエアを買うことはない。ここではソフトウエアには価値がなく、データに価値があるのだ。デジタル作品はデータだけでは自立できず、それを見るためにはインターフェイスが必要で、そのインターフェイスもまた作品の一部であったりする。実際インターフェイスだけで出来ている作品もあるぐらいである。というわけで作品の輪郭はどんどん膨張してしまうのである。',
    state6_2: '　いずれにしても、デジタル作品を売ることはなかなか難しいので、僕自身はより多くの人にコピーを取ってもらうことだけが、唯一作品を長生きさせる方法だと考えてきた。コピーされた数が多いほど、どこかに必ずそれは生き延びることになるだろう。結果、僕自身がやらなくてはならないことは、相手に「コピーを取りたい」と思わせることである。\n' +
      '\n' +
      '　さて、ここでやっと問題のNFTの登場である。NFTはこうしたデジタルの生産物に代替不可能性を与えることであり、NFTは、デジタル作品に概念的な意味での一回性を設けることができるというのだ。これは画期的であり、また問題である。',
    state7_1: '7\n' +
      '\n' +
      '　芸術が単なる消費財と異なるのは、その経験にある。音楽に喩えて話すと、原始の時代から音楽を持ち運ぶことはできなかった。そもそも音楽は聴く人＝演る人であり、その場に関与した人たちだけがその時間を共有するものであって、そして即座にその場で消え去っていくものだ。これが現在のように売り買い可能になったのは、レコードという音楽を物質に閉じ込める技術が生まれて以降の話だ。ここで初めて演る人と聴く人が切り離され音楽が運搬可能になった。ところが、ここにレコードを所有することと音楽を聴くという体験の間に混同が起こり、レコードを買うこと＝音楽が所有できるという錯覚が生まれたのだ。',
    state7_2: '　ここには芸術鑑賞に関する本質的な問題がある。コンサートで聴いた音楽は所有できない。それは消えていく。美術館で見た作品は所有することができない。眼に焼き付けて帰るだけだ。しかし、そこには経験が残る。この経験の価値のために私たちは入場券を買うに違いない。デジタル作品の経験は、こうした人間本来の芸術経験について改めて考える機会を与えてくれるのだ。物質性のないデジタル作品は、ネットワークによって容易く国境を越えてしまうので、これまで議論されてきた文化、特定の風土や言語と密接な関係がある文化とはことなった空間にも新しい文化の生まれうる余地ができたのだ。',
    state7_3: '　2009年にフィルムとして初めて重要文化財指定を受けたフィルムは『紅葉狩り』というフィルムである。九代目市川団十郎が更科姫を演じるフィルムなのだが、これは天皇臨席のために特別に組まれた御前撮影会で、撮影の現場を天皇自身が見たという事実が選定の理由になっている。重要文化財は日本中に点在するが、多くの石碑には天皇がその場で詠んだ歌が彫られている。ということは、この国では天皇の眼差しという経験が、最高位の文化的な価値の証明となっているのである。「経験が価値である」という考え方には賛同するが、その経験の中身は問われていない。さらに、この国の共同体を象徴する天皇の経験＝国民の経験であると断言されていることには大いに問題を感じる。',
    state8_1: '8\n' +
      '\n' +
      '　作品の価値のさまざまなありようをこのように考えてみると、経験を共有する共同体と作品の間には密接な関係があるということ、さらにそれがこれまで国という単位でなされてきたことが、これからはネットワークによって、国という単位を軽々と越えてゆくだろうということがわかる。アーティストの使命は作品を作るばかりではなく、その作品の価値を共同体と共有できるかが問題になるだろう。ある作品の価値が、ある共同体の中で保証される時、作品はその共同体の文化の一部になると考えられうるからである。遠く離れた人たちがネットワークで共通の経験をすることが可能になると、共同体は物理的に近い人たちである必要もない。芸術は地域に留まるものではなく、拡散していながら密度のある空間の中で経験をシェアするコモンズたちのものになっていくだろう。NFTがこれまでのような売買可能性をデジタル作品に与えてくれることは喜ばしい反面、もう一度芸術の価値について考え直すことも重要であると考える次第である。',
    state8_2: '　このプロジェクトが同じ興味を持っていてもこれまで知らないでいた人たちを結びつけて、ここに未見のコモンズが生まれることを夢見て、。「Brave New Commons　素晴らしき新世界。」',
    button_purchase: '購入ページへ',
    contact: 'お問い合わせ',
    support: '協力',
    web_support: 'WEB制作',
    coordinator_support: 'コーディネート',
    assistant_support: 'アシスタント',
    continue_purchase: '購入手続きに進む',
    purchase_title: '購入する作品を選んでください',
    purchase_desc: '購入参加したい作品をクリックして、黄色い枠を付けてください。複数作品を同時に指定することができます。購入手続きに進むと個別作品の数量を追加決定することができます。',
    purchase_num: '作品番号',
    purchase_price: '価格',
    purchase_amount: '個数',
    purchase_subtotal: '小計',
    purchase_fee: '参加費',
    purchase_sum: '合計',
    purchase_notice1: '表示されている作品価格は、参考となる現在価格であり、最終価格はプロジェクト終了後に決定されます。最終的な支払い総額は、プロジェクト終了後に、PayPalに登録されているメールアドレスへ、お送りするメールにてお知らせします。',
    purchase_notice2: '購入申し込みのためにお支払いいただく参加費合計',
    purchase_notice3: '申し訳ありませんが、Paypalでのみの決済となります。',
    purchase_pay: '決済する',
    purchase_cancel: 'キャンセル',
    purchase_success: '決済が完了しました。',
    thanks_top: 'トップへ戻る',
    terms_title: '藤幡正樹「Brave New Commons」参加規約',
    terms_sign: '2021年12月10日\n' +
      '合同会社コマンドA（3331 Arts Chiyoda）\n' +
      '藤幡正樹',
    terms_desc1: '「Brave New Commons」は、NFTを利用することで可能となるデジタルデータ作品の所有を、従来型の物質に根ざした唯一性ではなく、デジタルデータの特性を活かした新しい所有のあり方として考えるプロジェクトです。ひとつは「Subdivision方式」と呼ぶもので、作品価格を作家が任意に決定した価格を購入者数で割り算することで決定します。結果、購入者数が多い作品ほど最終価格は低くなります。また、デジタルデータにオリジナルと複製の区別がないことから、これは結果的に複数の所有者が、まったく同じ作品を持つことになるので、いわゆる作品の「共同所有」とはことなった形態、「分散所有」と呼ぶべき形態を取ります。',
    terms_desc2: '参加申込者は、作品譲渡者であるアーティスト藤幡正樹の本プロジェクトにおける代理人である合同会社コマンドA（3331 Arts Chiyoda運営会社）に、以下の条項に同意した上で、参加費（1100円×お申し込み件数）を支払うことで、本プロジェクトに参加することができます。プロジェクトの参加時点で作品の売買契約が成立するものではなく、また参加費は、申込み後はいかなる理由によっても返金することはできません。予めご了承ください。',
    terms_desc3: '■特典\n' +
      '該当作品に対し、一番初めに参加申込をされた方には、「符号理論／Cording Theory」展（会期：2021年10月28日〜11月14日）にて展示されていたデジタル作品の出力及び額物を進呈します。辞退者が出た場合は、次点の方にその権利が移行します。',
    terms_desc4: '■参加申込の完了\n' +
      '・作品のエディション数と購入価格は、作品の購入者数によって変動することをご了承の上、プロジェクトへの参加をお申し込みください。\n' +
      '・本プロジェクト作品には著作者である藤幡正樹による作品規約が設定されています。参加申込者は規約の内容を確認、ご同意いただけますようお願いいたします。各作品における規約内容は全て同一です。規約内容は以下リンクからご確認ください。',
    terms_desc5: '・参加費は、指定のウェブサイトからPayPal（オンライン決済サービス）によって決済をお願い致します。支払いが確認でき次第、参加者に対する申込みの完了通知をもって参加申込の完了となります。「符号理論／Coding Theory」展の会場で申し込みされた方については、2021年12月20日（月）までに、PayPalで参加費をお支払ください。お支払が確認できない場合は、キャンセルとさせて頂きます。',
    terms_desc6: '・参加費のお支払後は、藤幡正樹又は合同会社コマンドAの事前の承諾なく申込をキャンセルすることはできません。またこの場合参加費のご返金はいたしかねますので、ご了承ください。',
    terms_desc7: '■プロジェクトの終了\n' +
      '・参加申込締切後、2022年1月31日23時59分59秒 時点での参加者数が各作品のエディション数として定まり、同時に最終価格が決定します。最終価格の支払い請求はメールにてお知らせします。参加者は、指定された期限（2022年2月15日）までに、ご案内する指定の方法でお支払をお願い致します。',
    terms_desc8: '・期限までにお支払が確認できない場合には、作品の譲渡は行われません。また、そのエディション番号は欠番となります。エディションが欠番となった場合でも購入価格の再調整は行われません。',
    terms_desc9: '・最終価格が1円を割ってしまった場合には、支払いが不可能となりますので、最終価格を1円とさせて頂きます。',
    terms_desc10: '・欠番を除く全てのエディションのCert.の発行と譲渡申請のメールでの通知をもって、本プロジェクトは終了します。',
    terms_desc11: '・本プロジェクトの主催者である合同会社コマンドAへの返品（払い戻し）は認められません。',
    terms_complete: '参加申し込みの完了',
    terms_end: 'プロジェクトの終了',
    works_term: '作品規約',
    privacy_policy: 'プライバシーポリシー',
    to_artwork_page: '作品ページへ',
  },
  en: {
    hero_title: '"Brave New Commons" is a project concerning the value of artwork and its price.',
    hero_date: 'Masaki Fujihata 24 Oct. 2021',
    hero_desc: '“Brave New Commons” is a project considering the ownership of digital artwork made possible using NFTs, not based on a uniqueness rooted in conventional materials, but as a novel means of ownership utilizing the properties of digital data. One such means, called the “subdivision method”, determines the price of an artwork by dividing an arbitrary price set by the artist by the number of purchasers. A higher number of purchasers results in a lower final price. Also, as there is no distinction between the original and duplicate in digital data, multiple people will own the exact same artwork, creating a form of “distributed ownership” different from what some call the “co-ownership” of artwork.',
    ars_text: 'I am going to present two new projects at Ars Electronica Festival, Sep. 7-11, 2022,\n which were derived from the first exhibition “Coded Theory” at 3331 art center, Tokyo\n in Oct. 2021.',
    anno_desc1: 'Participation application from the web has started. The participation fee is 1000 yen ( + tax ) per item.',
    anno_desc2: 'PayPal is the only payment method accepted, so obtaining a PayPal account is a prerequisite. Please note that the email address registered in your PayPal account will be the receiving address for NFT transfers.',
    anno_desc3: 'You can purchase multiple works at the same time, and you can also purchase multiple copies of a single work on the purchase page.',
    pj_title: 'Protocol',
    pj_desc1: 'The listed artworks that were done by Masaki Fujihata become the objects to sell.',
    pj_desc2: 'Although the artwork had a certain value, it was not given a price as a digital artwork,\n' +
      'These artworks were especially unpublished until now and made around 1985 using MacPaint software on Macintosh computers.',
    pj_desc3: 'The seller, Masaki Fujihata, put an arbitrary price on each artwork, as a starting price.',
    pj_desc4: 'The amount paid by the purchaser is the price tag (original price) divided by the number of applicants. And if there are more participants, the price will get lower. In addition, participants will make more efforts to reach out to more participants and the price will go down even more. Therefore, if there are more people who want the work, the price will get cheaper, and if the work is less popular, the price will go up.',
    pj_desc5: 'After the application deadline is over, NFTs will be issued using the number of participants as the number of editions. After the purchase is confirmed, the work will be transferred from Masaki Fujihata to each purchaser.',
    works_title: 'Works',
    work_title: 'Title',
    work_num: 'Number',
    work_date: 'File created',
    work_price: 'Price',
    work_price_unit: 'JPY',
    work1_date: 'Friday, January 27, 1989, 0:02',
    work1_desc: 'A very rare meaningless scribble, therefore this item is extremely valuable. It is purely a miracle that could survive over 30 years. Please have a look at this trajectory, a jaggy line made by an old style Macintosh’s ball mouse.',
    work2_date: 'Friday, January 27, 1989, 1:54',
    work2_desc: 'It is precious that I can’t remember the purpose of this drawing titled “background. Because of that we can face this drawing without any biases. It is obvious that the lines were drawn from top to bottom, because of its graduation, but how this graduation was made by the 1 bit depth picture. There might be a dither technique applied. At the bottom there is a horizontal line.',
    work3_date: 'Saturday, June 3, 1995, 11:29',
    work3_desc: 'File created date is 1995, but in the picture, there is a signature “Masaki Fujihata April 1985,” perhaps by accident the original time stamp disappeared during the copy process. Picture image is very complex and was done perfectly and professionally. Related files: 007, 028.',
    work4_date: 'Tuesday, January 17, 1989, 23:27',
    work4_desc: 'MacPaint has a function called “Trace Edges,” I played a lot with this. This function generates additional lines in parallel along with the original line. It was fun to add more contours and contours, the process looked like animating lines. Related file: 008.',
    work5_date: 'Saturday, June 3, 1995, 11:30',
    work5_desc: 'The file has the same time stamp problem with number 003, there is a line “Masaki Fujihata April 1985” in the picture. While the Trace Edges function adds lines, the resulting meaning gets collapsed = entropy increasing. This is one of the best-resulted pictures using this function, but unfortunately, I cannot trace the process of making this picture, because there might not be enough storage. It was made before the Hard disk came in. Related files: 003, 026.',
    work6_date: 'Friday, January 27, 1989, 1:50',
    work6_desc: 'There might be some accident that happened while in the process of duplicating, because the noises at the bottom were not made by hand. Usually it would collapse even with one bit of error in the copy process, but it could survive. So this is a precious picture, and also the content may have predicted this happening. Here is a miracle.',
    work7_date: 'Saturday, June 3, 1995, 11:29',
    work7_desc: 'This might be one of the etudes for the picture No.003, “DONDONs3,” because this was in the same folder. All of this group of pictures are experimentation of using a function Trace Edges and minimizing ways to recognize faces. Related files: 003, 028.',
    work8_date: 'Wednesday, January 18, 1989, 21:34',
    work8_desc: 'In contrast to the variant picture No. 004 “body2,” which looked happy for flying, but this figure in the picture looked tired with too many edges. Related file: 004.',
    work9_date: 'Saturday, February 23, 1985, 4:49',
    work9_desc: 'The concept behind this picture is unclear. According to the file name, it is related to a kind of mask, which is indicating face masks decorated on the surface of pottery from the Jomon period, Japanese prehistoric time. But these parallel lines were automatically made by the Trace Edges function.',
    work10_date: 'Saturday, June 3, 1995, 11:30',
    work10_desc: 'This file also has the same time stamp issue. It might have been created in 1985. This drawing shows the fun of making figures with a heavy ball mouse on Macintosh. It is a new “animism” in the digital domain. Related file: 023.',
    work11_date: 'Thursday, September 12, 1985, 6:45',
    work11_desc: 'Black telephone device. The picture revealed that digital drawing by Macintosh computer and analog telephone co-existed in 1985. I began to remember the color, weight and the voices coming through the black telephone.',
    work12_date: 'Sunday, December 15, 1985, 16:47',
    work12_desc: 'I am not sure, but we were asked to make a new character from one famous designer at an advertising agency, who saw a series of articles “Frogs Inc.” in Brutus magazine. The Kappa was created. Kappa is an imaginary living creature in Japanese folklore. The motivation to make this drawing is for advertisement. But these are obviously not used, outside of the selection and still very charming. I hope these 4 pictures will be sold as one set. Related files: 013, 014, 015.',
    work13_date: 'Sunday, November 24, 1985, 0:12',
    work13_desc: 'This picture and No. 015 were on the same floppy disk. No. 012 and 014 were found on the hard disk. Both had come from different routes and showed some incompleteness rather than No. 012 and 014. Related files: 012, 014, 015.',
    work14_date: 'Sunday, November 24, 1985, 18:26',
    work14_desc: 'More variations from No. 012. Related files: 012, 013, 015.',
    work15_date: 'Wednesday, November 20, 1985, 1:46',
    work15_desc: 'Black Kappas. There are different types of stair-like rectangles at the edge, which were generated by using enlarge and shrink functions. Related files: 012, 013, 014.',
    work16_date: 'Tuesday, November 29, 1988, 18:37',
    work16_desc: 'I was astonished with this discovery that the picture consisted only of ovals and the result looked like a wireframe computer graphics. MacPaint can not make angled oval, only horizontal or vertical. So it’s a tricky technique to impress a depth. I hope the buyer takes all 3 pictures at once.',
    work17_date: 'Tuesday, November 29, 1988, 18:22',
    work17_desc: 'One of a series of Ekubo pictures. Related files: 016, 018',
    work18_date: 'Tuesday, November 29, 1988, 18:36',
    work18_desc: 'One of a series of Ekubo pictures. Skills and technique using MacPaint looked very high level. Related files: 016, 017.',
    work19_date: 'Tuesday, February 14, 1989, 19:38',
    work19_desc: 'This is not a bitmap picture, which was made by using Adobe Illustrator, perhaps version 88. As far as I can remember I experimented with a lot of curved lines. And the file includes an alpha channel, so that the background is transparent. The circular line is red. As the title “XX” may indicate a temporal file, but is one of the best results from my excavation. Related files: 020, 021.',
    work20_date: 'Friday, January 27, 1989, 1:10',
    work20_desc: 'These are the days I experimented using Adobe Illustrator. This is one of the etudes. The file was translated to a bitmap file, so here is no alpha channel. Related files: 019, 021.',
    work21_date: 'Tuesday, February 21, 1989, 17:29',
    work21_desc: 'This file is also an Adobe Illustrator file with an alpha channel. The color of the line is green. I really cannot remember where the title “kabu” came from. Lovely 30 years time difference. Related files: 019, 020.',
    work22_date: 'Saturday, June 3, 1995, 11:30',
    work22_desc: 'I found quite many picture files related to this lion. One sheet of paper shows the process of making that started from hand drawing an animal lion and transformed it into a more abstract form. It is exceptional that this picture focuses only on heads. Related file: 025.',
    work23_date: 'Friday, September 6, 1985, 19:50',
    work23_desc: 'Face, without any background information. It is obvious there was no client and is a kind of scribble that has similarities to the picture No. 010. Some contour lines of the face were made with numbers, 3, 6, 6, 3, therefore starting from numbers and evolving to a face, like a play with numbers and face. Related file: 010.',
    work24_date: 'Wednesday, April 16, 1986, 12:26',
    work24_desc: 'The Japanese term “Suuji” means numbers. So the title means mask made with numbers. This might be an etude for the series of articles “Frogs Inc.” I could salvage almost all of the pictures which were used for this article. As you can imagine, at that period there was no way to pass the digital data to the printing company. Therefore I need to make a conventional block copy on a sheet of paper. It might be a source for cutting and making a composition for a block copy.',
    work25_date: 'Saturday, June 3, 1995, 11:29',
    work25_desc: 'One of the important advantages of digital production is producing and testing adequate variants. These variations came out from other origins, but I did not include those originals. Related file: 022.',
    work26_date: 'Saturday, June 3, 1995, 11:29',
    work26_desc: 'There is a line “Masaki Fujihata Feb. 1985” in the picture, even though the file created date is 1995. Bitmap picture does not have any color except black, there are only patterns which can be filled in a space. So, this picture was made with a mixture of functions, Trace Rdges and fill-in Patterns. Related file: 005.',
    work27_date: 'Friday, March 14, 1986, 3:09',
    work27_desc: 'Such as “EMO-JI,” face-like symbols are fun to make. Once the editor said “Masaki could be a Japanese DIsney.” The title “Gas” is also good enough.',
    work28_date: 'Saturday, June 3, 1995, 11:29',
    work28_desc: 'Picture No. 003, 007, and this has the same word “DONDONs.” Is this a name of the character or the specific naming from the process of drawing? I hope someone buys these as a set. Related files: 003, 007',
    work29_date: 'Wednesday, June 6, 1990, 21:33',
    work29_desc: 'In the years around 1989, I have been spreading a concept “Computers must be a Mind- Reflector.” This picture came from my drawing, which I made at a meeting. Later one of the participants transformed the picture image to an Illustrator file by using the Trace function. Digested lines have less information, but it is good. Also texts could not have proper font, perhaps because of wrong code. Then this becomes a precious abstract painting. ',
    work30_date: 'Saturday, June 3, 1995, 11:35',
    work30_desc: 'Again at last, the file name is “tmp,” but the content is a creation of kanji font type, “Masturbation.”  Yes, producing artwork is a masturbation of the artist. I hope the buyer hangs this picture in his/her room.',
    state1_1: '1\n' +
      '\n' +
      'All of the artworks for sale are unpublished. They were made with MacPaint software on a Macintosh computer in the eighties by myself, Masaki Fujihata. I salvaged this 35 year-old picture data from 400K/800K floppy drives in recent weeks. Each artwork was made in a digital reproduction environment; fundamentally, this is truly fresh digital data, without any artificial additions. From the beginning, one of the important philosophies of Macintosh was WYSIWYG ( What You See Is What You Get ), therefore the dot pitch of the display is 72 dots/inch, which is a common measurement unit in the printing industry, and the actual display resolution is 512x384 dots. MacPaint software holds a letter size rectangle frame (8 x10 inches = 576x720 dots); a part of this frame is shown in a small window. When you command to print it out, the exact same letter size image comes out. There were several people who cared a lot about the relationship between the computer world and the real world.\n' +
      'In the year of 1982, I was involved in the launch of a computer graphics production company, and I was called a “CG artist.” The company invested 3 million dollars for the computer equipment and the CG software was programmed from scratch by our team. This meant that I used the most expensive computer at the company, and in my home I used the Macintosh personal computer, which suddenly appeared in the consumer market. At the very beginning, there were only MacPaint and MacWrite softwares inside the Macintosh, therefore there was no interest from serious business people. Most of the buyers were just enthusiasts, who wanted to see the future of computers and always gathered at the Apple Computer shop in town.',
    state1_2: 'I became an evangelist, talking about the fun of using Macintosh here and there. At last, an editor of a magazine gave me a chance to start a series of articles which included digital illustration. Then suddenly, I had the additional job title “Illustrator.” This series was titled “Frogs Inc.,” in Brutus magazine. Later, I gained a good reputation from several other professionals for these illustrations, but this was not only realized by my ability, but the vision materialized by Macintosh that inspired me to use the software for my illustrations.\n' +
      'The MacPaint is a revolutionary software, which asks us to reconsider the origin of stationary. Conventionally, each stationary has different types of restrictions, and each restriction asks us to discover new solutions. In a way, as a metaphor, it is like calligraphy using LEGO blocks. This series of digital drawings is in line with those restrictions; when those restrictions become visible, you can enjoy the drawing from a different angle.',
    state2_1: 'These are unpublished works, which means they are self-motivated drawings, and pure pictures. On the other hand, they are forgotten works, given no attention until now,  even by myself. Metaphorically speaking, they are salvaged artworks from trash. I am not confident whether these artworks will sell or not. It is not easy to analyse the value of these artworks by myself. Therefore, I plan to open up the discussion platform to the purchasers. \n\n' +
      '\n' +
      '\n' +
      '2\n' +
      '\n' +
      'Practically speaking, I plan to assign prices to each artwork collectively with purchasers. By the way, how is the price decided fundamentally? For example, consumer products and their price are calculated as the production cost + the distribution cost  + the profit margin at the retail shop. Therefore, the producer tries to cut down production costs to win against other competitors. In contrast, the price of collectible artwork increases when the number of those who desire to own the artwork increase, because commonly, the artwork is not a fungible object--it is a unique artifact. It is the nature of the market; a structure of supply and demand.',
    state2_2: 'In the early period of the 20th century, Walter Benjamin stated that the authority of an artwork emerges from, "its presence in time and space, its unique existence at the place where it happens to be." He called this authority its "Aura" in his essay, "The Work of Art in the Age of Mechanical Reproduction," and celebrated the coming arts produced by mechanical reproduction, such as photography and cinema.\n\n' +
      'However, ironically, what actually happened from the postwar period to the present day was a history of proving that even artworks using mechanical reproduction can have "Aura". In other words, it was possible to disguise "one-timeness" even in works using mechanical reproduction, and to give authority to them.This authority was demonstrated through an action in the opposite direction of the original concept of authority. Authority became possible by arousing the desire to "own" a work. This is what fashion brands had been doing before the art industry. What is happening is that artworks that sell well and sell at high prices have become authoritative.',
    state3_1: '3\n' +
      '\n' +
      'Behind the current socio-economic structure, investors have taken on a third-person role, recently added to the traditional role of capitalists and workers (=consumers). Traditionally, capitalists use surplus assets to invest in the renewal of factory equipment, etc., but investors are people whose job is to invest. Their behavior and how their buying and selling information eventually affect the rise and fall of corporate value is already commonplace. The information that money produces ends up twisting the money itself. Investors are constantly observing trends, so what is happening in the investment market is far from our daily lives. Events here go up and down completely independent from our daily life, and have implications across countries.\n' +
      '\n' +
      'However, the art market has suddenly emerged as a target in recent years as one of the destinations for surplus assets. Until now, museums and art galleries have been the main players in the art industry, but recently, the focus has shifted to "art fairs" such as Art Basel. ',
    state3_2: 'Behind this is the presence of these investors, who consider art to be the safest destination for surplus assets. This safety is due to the recognition that the value of those works of art is guaranteed by a huge community, the Nation State--in other words, concepts of "history" and "culture". Culture is the identity behind the community, and its value is unlikely to fluctuate significantly as long as the community exists. So to speak, Horyuji temple is a part of Japanese culture, and its price is almost infinitely high, so no one can buy it anymore. But can culture be an investment destination for surplus assets? This is not possible in the first place, and I think it will become a big problem in the future.',
    state4_1: '4\n' +
      '\n' +
      'In contrast, let us focus on the information industry and how they have been controlling value and price. In the 70s and 80s, they sold software based on the same scheme as packaged commodities sold at specialty stores, or custom-made, purchased like machine tools. Such software was also subject to depreciation. However, recently, there is a shift to services that sell the right to use software without letting the user own it. No, perhaps we never bought or owned the software from the beginning, but were only allowed to use it. Consider the following argument. Originally, to buy something and make it your own was to buy the possibility of reorganization (including breaking it). It is common sense to modify the shape of the grip of the knife you bought to match the shape of your hand. The current free software movement has picked up this reorganization potential in a good way. A software distribution method that guarantees the possibility of reorganization has been realized. In the past, there was a debate about whether software was a patent or a copyright, but the reason why it was ultimately considered a work rather than a patent is because the software must have the same function as the citation potential of a document in this sense. Therefore, this argument was correct.\n' +
      '\n' +
      'Especially from the 80s to the 90s, there were movements such as R. Stallman\'s GNU (GNU’s Not Unix) software, which advocated the idea of “copyleft” that "does not give up copyright but allows others to use it freely," and the Public Domain Software movement, which is considered public property. Behind these movements was a battle between ATT and academia over Unix licensing, and there was a lot of debate over ownership, use, and programmers versus users.\n' +
      '\n' +
      'I remember that it was at the Apple Shop that I first learned the term “PDS(Public Domain Software).” Floppy disks containing a large amount of software were lined up in the shop, and if you bought a floppy disk, you were told that you can copy as much of your favorite software as you can into the floppy disk. It was like a candy shop. Actually, the contents were a lot of unfinished software, and it contained a bomb that had to be rebooted, but it was very inspiring to be able to touch various ideas. However, in response to this movement toward social software sharing at the time, Apple was relatively cold to the enthusiastic software programmer community that it had grown since the launch of Macintosh, and with the creators. Apple took up a policy of building a wall between users. They might have decided that it was necessary to separate the computer community (commons) from the computer as a commodity in order to grow their business.\n',
    state5_1: '5\n' +
      '\n' +
      'Around the time of the bubble, in 1990, board chairman Ryoei Saito of Daishowa Paper Co., Ltd. made a successful bid for Van Gogh for about 125 million dollars. Two days later, he made a successful bid for Renoir\'s painting for about 119 million dollars. He was allegedly said to put the two masterpieces together in a coffin and burn them when he died, and bought swords from art collectors around the world. This story has made society aware that even if you own a historic cultural heritage-class painting, it cannot be reorganized.He claims this was a joke for the general public, but even in jest, the fact that he can make such a joke clearly shows how he thinks of artwork as consumer goods. Although these works of art are sold at auction, they are not so-called consumable products. The price is high, and you are only buying a right of use. This means that the artist, or the art market, including galleries, indicate those who strive to bring their work to the “outer zone” of products for consumption. This zone is, so to speak, "making something that cannot be owned."',
    state6_1: '6\n' +
      '\n' +
      'Digital works are not created by mechanical reproduction technology such as photography, cinema and printing, but are creations in which the digital technology itself is a work, and data is converted into perceptible objects such as displays and speakers. It is a work that can be seen by humans for the first time through its converting device. For example, looking at an image on a website means looking at the data copied on the display. Being able to copy as much as you want means that you are oversupplied from the beginning, and the price is getting cheaper and cheaper, and at the end it is going to zero. Software is now an infrastructure for data collection, free and scattered, and many people don\'t buy it. Software has no value; data has value. Digital works cannot stand on their own with data alone, and they need an interface to see it, and that interface is also part of the work. In fact, some works are made only of interfaces.',
    state6_2: 'In any case, it\'s difficult to sell digital works, so I\'ve always thought that getting more people to make copies is the only way to make a work last longer. Isn\'t it wrong to think that the more copies there are, the more it will survive somewhere? As a result, all I have to do is make the other person "want to make a copy".\n' +
      '\n' +
      'OK, back to NFTs. NFTs make these digital products irreplaceable, and give digital works a conceptual one-timeness.',
    state7_1: '7\n' +
      '\n' +
      'It is in that experience that art is different from mere consumer goods. Analogizing with music, music was not portable before modern times. In the first place, music required a listener and a performer, and only those who were involved in a given place could share that time, and the moment disappeared immediately there. It wasn\'t until technology for confining music called “records” was born that music became a thing to be bought and sold as it is now. Here, the person who performed for the first time and the person who listened to it were separated, and music became portable. However, there was confusion between owning a record and listening to music, creating the illusion that buying a record meant owning its music.',
    state7_2: 'Here is the essential problem of art and its ownership. We can\'t own the music we listened to at a concert. It will disappear in the air. You cannot own the works you saw in a museum. You can only burn it into your eyes and mind. However, experiences remain. We buy admission tickets because of the value of this experience. Perhaps the experience of digital works gives us the opportunity to reconsider these original human artistic experiences. In addition, immaterial digital works are easily crossed by networks, prompting a rethinking of the cultures that have been discussed, and those that are closely related to a particular climate or language.',
    state7_3: 'The first film to be designated as an important cultural property in Japan in 2009 was called "Autumn Leaves Hunting". Ichikawa Danjuro IX plays Princess Sarashina, but this was a filming session specially organized for the emperor\'s presence, and the fact that the emperor himself saw the shooting scene was the reason for the film’s selection. Important cultural properties are scattered all over Japan, but many stone monuments are engraved with songs that the emperor wrote on the spot. This means that the experience of the emperor\'s gaze in this country is proof of the highest cultural value. I agree with the idea that "experience is value", but the content of that experience is not questioned. Furthermore, I take great issue with the assertion that the experience of the emperor, which symbolizes the community of this country, is the experience of the people. Who decided this?',
    state8_1: '8\n' +
      '\n' +
      'Thinking about the value of the artwork in this way, there is a close relationship between the artwork and the experiences shared in the community. Conventionally, this has occurred in the community unit of the country so far. This means that in the near future, or already, this community can go beyond the border of the country through the digital network. The artist\'s mission will not only be to create a work, but to share the value of that work with the borderless community. This is because when the value of a work is guaranteed within a community, the work is considered to be part of the culture of that community. Communities do not have to be physically close, as distant people can have a common experience in the network. Art will not stay in a given region, but will belong to the commons who share their experiences in a diffuse yet dense space.',
    state8_2: 'このプロジェクトが同じ興味を持っていてもこれまで知らないでいた人たちを結びつけて、ここに未見のコモンズが生まれることを夢見て、。「Brave New Commons　素晴らしき新世界。」',
    button_purchase: 'Purchase',
    contact: 'Contact',
    support: 'Support',
    web_support: 'Web developer',
    coordinator_support: 'Coordinator',
    assistant_support: 'Assistant',
    continue_purchase: 'Continue to purchase',
    purchase_title: 'Select a piece to purchase',
    purchase_desc: 'Click on the work you wish to purchase and put a yellow frame around it. You can specify multiple works at the same time. When you proceed to the purchase process, you will be able to determine additional quantities for individual works.',
    purchase_num: 'Number',
    purchase_price: 'Price',
    purchase_amount: 'Quantity',
    purchase_subtotal: 'Subtotal',
    purchase_fee: 'Participation Fee',
    purchase_sum: 'Total',
    purchase_notice1: 'The price of the artwork shown is the current price for reference only, and the final price will be determined after the project is completed. The final price will be determined after the project is completed. The final payment amount will be announced in an email sent to the email address registered with PayPal after the project is completed.',
    purchase_notice2: 'Total participation fee to be paid for the purchase application',
    purchase_notice3: 'Payment can only be made via Paypal.',
    purchase_pay: 'Purchase',
    purchase_cancel: 'Cancel',
    purchase_success: 'Payment in successfully',
    thanks_top: 'Back to Top page',
    terms_title: 'Masaki Fujihata “Brave New Commons” Terms of Participation',
    terms_sign: 'December 10, 2021 \n' +
      'commandA, LLC (3331 Arts Chiyoda)\n' +
      'Masaki Fujihata',
    terms_desc1: '“Brave New Commons” is a project considering the ownership of digital artwork made possible using NFTs, not based on a uniqueness rooted in conventional materials, but as a novel means of ownership utilizing the properties of digital data. One such means, called the “subdivision method”, determines the price of an artwork by dividing an arbitrary price set by the artist by the number of purchasers. A higher number of purchasers results in a lower final price.  Also, as there is no distinction between the original and duplicate in digital data, multiple people will own the exact same artwork, creating a form of “distributed ownership” different from what some call the “co-ownership” of artwork. ',
    terms_desc2: 'Those wishing to participate must pay a participation fee (1100 JPY per application) after agreeing to the following terms with commandA, LLC (the company operating 3331 Arts Chiyoda), which represents the artist transferring the artwork, Masaki Fujihata, for this project. Please be aware that participation in this project does finalize an artwork sales contract, and that the participation fee will not be refunded for any reason following an application to participate.',
    terms_desc3: '■ Benefits \n' +
      'The earliest applicants for participation in this project will receive an output of their chosen artwork and a corresponding frame, exhibited at the “Coding Theory” exhibition (October 28 to November 14, 2021). If a participant offered this benefit withdraws, this benefit will be transferred to the next participant. ',
    terms_desc4: '■ Completion of Application for Participation \n' +
      '・Participants should apply for this project while understanding that the number of editions and the purchase price of a given artwork may vary depending on the number of its purchasers. \n' +
      '・The contract terms for the artwork are set by the artist, Masaki Fujihata. All terms and conditions are the same for each work. Please kindly review these terms, detailed at the following URL, before agreeing to participate. ',
    terms_desc5: '・Please pay the participation fee through PayPal (an online payment service) from the designated website. Once the payment of this fee is confirmed, the participation application will be completed with a notification sent to the applicant. Those who applied for participation at the venue for the “Coding Theory” exhibition should complete their participation fee payments through PayPal by Monday, December 20, 2021. If a payment cannot be confirmed, the participation application will be cancelled. ',
    terms_desc6: '・Once a participation fee has been paid, the application cannot be canceled without the prior approval of Masaki Fujihata or commandA, LLC. If an application is cancelled, the participation fee will not be refunded.',
    terms_desc7: '■ Project Completion\n' +
      '・After the application deadline, the number of participants as of January 31, 2020 at 23:59:59 will be set as the number of editions for a given artwork, and the final artwork price will be calculated at the same time. Participants will be notified of the final artwork price with a payment request via email. Participants will be requested to complete payment by the designated deadline (February 15, 2022) through a designated payment method.',
    terms_desc8: '・Artwork will not be transferred if full payment cannot be confirmed by the deadline. Any artwork editions with unconfirmed payments will be omitted. If an edition number is omitted, the purchase price will not be recalculated. ',
    terms_desc9: '・If the final artwork price becomes less than 1 JPY, the final price will be set at 1 JPY to make payment possible.',
    terms_desc10: '・This project will conclude with the issuance of Cert. (blockchain certificates) for all artwork editions (except for omitted editions) and an artwork transfer notification email. ',
    terms_desc11: '・The organizer of this project, commandA, LLC, will not accept returns or carry out refunds. ',
    terms_complete: 'Complete the registration process.',
    terms_end: 'End of project',
    works_term: 'Terms of Works',
    privacy_policy: 'Privacy Policy',
    to_artwork_page: 'Artwork detail',
  },
};
