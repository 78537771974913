import React from 'react'
import tw from 'twin.macro'

const Container = tw.button`w-full font-bold h-16 flex flex-col items-center justify-center bg-gray-400 rounded-full px-5 leading-6`

export const ButtonRounded = (props) => {
  return (
    <Container {...props} />
  )
}
